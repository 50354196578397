import React, { FC } from 'react';
import styled from '@emotion/styled';
import { flexColumn, flexRow } from '$lib/style-helpers';
import { mq } from '$lib/helpers';
import { ImageTextSpotModel, ImageTextSpotStyle, ImageTextSpotType, SpotTheme } from '$models/spots-types';
import { ProductSpot } from './variants/product-spot';
import { StorySpot } from './variants/story-spot';

type Props = {
    hasFixedTagHeight?: boolean;
    productImageHeight?: number;
    imageTopLeftContent?: React.ReactNode;
} & ImageTextSpotModel;

// Spot that can be configured to show either a story or a product. This can also be a configured to be a hero.
// The spot can appear in a grouping context: ImageTextGroupSpot.
// With the exeption of a few props. This spot should not have logic or styling specific to the grouping.
// This should all be handled with overwrites in the ImageTextGroupSpot and this spot should only have its baseline as one single spot.
export const ImageTextSpot: FC<Props> = (props) => {
    return (
        <ImageTextSpotContainer
            spotType={props.spotName === 'Hero spot' ? 'Hero' : 'Content'}
            spotStyle={props.style}
            spotTheme={props.theme}
        >
            {props.style === 'Product' && (
                <ProductSpot
                    {...props}
                    type={props.spotName === 'Hero spot' ? 'Hero' : 'Content'}
                    hasFixedTagHeight={props.hasFixedTagHeight}
                    imageHeight={props.productImageHeight}
                    imageTopLeftContent={props?.imageTopLeftContent}
                />
            )}
            {props.style === 'Story' && (
                <StorySpot
                    {...props}
                    type={props.spotName === 'Hero spot' ? 'Hero' : 'Content'}
                    hasFixedTagHeight={props.hasFixedTagHeight}
                    imageTopLeftContent={props?.imageTopLeftContent}
                />
            )}
        </ImageTextSpotContainer>
    );
};

const breakPoint = 'frame';

export const ImageTextSpotContainer = styled.div<{
    spotTheme?: SpotTheme;
    spotType: ImageTextSpotType;
    spotStyle: ImageTextSpotStyle;
    isChildElement?: boolean;
}>(({ theme, spotTheme, spotType, spotStyle }) => ({
    ...flexColumn,
    position: 'relative',
    minWidth: 0,
    gridColumn: '1 / -1',
    backgroundColor: spotTheme?.backgroundColor,
    color: spotTheme?.textColor,
    ...(spotType === 'Hero'
        ? {
              width: '100vw',
              margin: `0 -${theme.sizes.siteGutter}`,
              marginBottom: theme.space[11],
          }
        : { marginBottom: theme.space[7] }),

    [mq(breakPoint)]: {
        ...flexRow,
        ...(spotType === 'Hero'
            ? {
                  width: '100vw',
                  margin: `0 calc(((100vw - ${theme.sizes.contentMaxWidth}) / 2) * -1)`,
                  marginBottom: theme.space[11],
              }
            : { marginBottom: theme.space[7] }),
        ...(spotType === 'Content' && spotStyle === 'Product'
            ? {
                  maxHeight: 492,
              }
            : {}),
    },
    [mq('lg')]: {
        ...(spotType === 'Hero' && {
            height: '40vw',
            maxHeight: spotStyle === 'Product' ? 520 : 750,
        }),
    },
}));
