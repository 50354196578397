import React, { FC, memo, useEffect, useState } from 'react';
import * as Collapsible from '@radix-ui/react-collapsible';
import styled from '@emotion/styled';
import { ISubMenuItem } from '$models';
import { Flex, Grid } from '$components/layouts';
import { mq } from '$lib/helpers';
import { splitToChunks } from '$lib/helpers/array.helper';
import { useTranslation } from '$hooks';
import { fullWidthSpot } from '$lib/style-helpers';
import { Link } from '$components/elements/link';
import { Responsive } from '$components/elements/responsive';

type Props = {
    subMenuItems: ISubMenuItem[];
};

export const SubMenuSpot: FC<Props> = memo(({ subMenuItems }: Props) => {
    const [menuChunks, setMenuChunks] = useState<ISubMenuItem[][]>([]);
    const [open, setOpen] = useState<boolean>(false);
    const { translate } = useTranslation();

    useEffect(() => {
        if (subMenuItems?.length) {
            setMenuChunks(splitToChunks(subMenuItems, subMenuItems.length > 4 ? 4 : subMenuItems.length));
        }
    }, [subMenuItems]);

    return (
        <CategorySubMenuContainer>
            <Responsive min="md">
                <DesktopSubMenu flow="column" columns={['1fr 1fr 1fr 1fr']} columnGap="small">
                    {menuChunks.map((c, i) => (
                        <MenuChunk column key={i}>
                            {c.map((l, i) => (
                                <SubMenuLink key={`${l.text}-${i}`} href={l.url} target={l.urlTarget}>
                                    {l.text}
                                </SubMenuLink>
                            ))}
                        </MenuChunk>
                    ))}
                </DesktopSubMenu>
            </Responsive>
            <Responsive max="md">
                <MobileSubMenu open={open} onOpenChange={(open) => setOpen(!!open)}>
                    <Flex column>
                        {subMenuItems?.slice(0, 5).map((l, i) => (
                            <SubMenuLink key={`${l.text}-${i}`} href={l.url} target={l.urlTarget}>
                                {l.text}
                            </SubMenuLink>
                        ))}
                    </Flex>
                    <Collapsible.Content>
                        <Flex column>
                            {subMenuItems?.slice(5).map((l, i) => (
                                <SubMenuLink key={`${l.text}-${i}`} href={l.url} target={l.urlTarget}>
                                    {l.text}
                                </SubMenuLink>
                            ))}
                        </Flex>
                    </Collapsible.Content>
                    {!open && !!subMenuItems?.length && subMenuItems.length > 5 && (
                        <Collapsible.Trigger as={SeeAllTrigger}>
                            {`${translate('menu.subMenuShowAll')} (${subMenuItems.length})`}
                        </Collapsible.Trigger>
                    )}
                </MobileSubMenu>
            </Responsive>
        </CategorySubMenuContainer>
    );
});

const CategorySubMenuContainer = styled.div(({ theme }) => ({
    ...fullWidthSpot,
    padding: `${theme.space[2]} 0`,
    borderBottom: `1px solid ${theme.colors.grey15}`,
}));

const DesktopSubMenu = styled(Grid)(({ theme }) => ({
    padding: `${theme.space[4]} 0`,
    [mq(0, 'sm')]: {
        display: 'none',
    },
}));

const MenuChunk = styled(Flex)(({ theme }) => ({
    '&:not(:last-child)': {
        borderRight: `1px solid ${theme.colors.grey15}`,
    },
}));

const MobileSubMenu = styled(Collapsible.Root)({
    [mq('md')]: {
        display: 'none',
    },
});

const SeeAllTrigger = styled.button(({ theme }) => ({
    all: 'unset',
    color: theme.colors.grey40,
    '&:hover': { cursor: 'pointer' },
}));

const SubMenuLink = styled(Link)(({ theme }) => ({
    marginBottom: theme.space[2],
}));
