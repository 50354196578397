import React, { FC, useRef } from 'react';
import styled from '@emotion/styled';
import { CarouselDrag } from '$components/elements/carousel-drag';
import { ImageTextSpotContainer } from '../image-text-spot';
import { flexColumn, flexRow } from '$lib/style-helpers';
import {
    ContentContainerProductSpot,
    ImageContainerProductSpot,
    PriceContainerProductSpot,
} from '../image-text-spot/variants/product-spot';
import { ContentContainerStorySpot, ImageContainerStorySpot } from '../image-text-spot/variants/story-spot';
import {
    ImageTextButton,
    ImageTextContentFlexWrapper,
    ImageTextContentTextWrapper,
    ImageTextSpotHeader,
    ImageTextSpotText,
} from '../image-text-spot/shared/styles';
import { StyledScrollBarContainer } from '$components/elements/carousel-drag/styled';
import { IFieldTypesModelLink } from '$models';
import { Link } from '$components/elements/link';
import { Button } from '$components/elements/button';
import { Flex } from '$components/layouts';
import { SvgIcon } from '$components/elements/svg-icon';
import { TagContainer, TagItem } from '../image-text-spot/shared/tags';
import { mq } from '$lib/helpers';
import { brandLeg } from '$constants/env';

type Props = {
    children?: JSX.Element[] | JSX.Element;
    title?: string;
    link?: IFieldTypesModelLink;
    cta?: string;
    numberOfSpots?: number;
    isTopSpot?: boolean;
};

export const ImageTextGroupCarousel: FC<Props> = ({ children, title, link, cta, numberOfSpots, isTopSpot }) => {
    const moduleWidthRef = useRef<HTMLDivElement>(null);
    const leftArrowRef = useRef<HTMLButtonElement>(null);
    const rightArrowRef = useRef<HTMLButtonElement>(null);
    const titleLength = title?.length;

    return (
        <ImageTextGroupCarouselContainer>
            {isTopSpot && !title ? (
                <></>
            ) : (
                <ImageTextGroupCarouselHeader titleLength={titleLength}>
                    <ImageTextGroupCarouselTitle>{title}</ImageTextGroupCarouselTitle>
                    <ImageTextGroupCarouselControls alignItems="center">
                        {link && !!cta && (
                            <Link href={link.url} target={link.target} alwaysShowUnderline>
                                {cta}
                            </Link>
                        )}
                        <ImageTextGroupCarouselArrows numberOfSpots={numberOfSpots}>
                            <ArrowButton ref={leftArrowRef} variant="custom">
                                <SvgIcon size={24} svg="chevronLeft" />
                            </ArrowButton>
                            <ArrowButton ref={rightArrowRef} variant="custom">
                                <SvgIcon size={24} svg="chevronRight" />
                            </ArrowButton>
                        </ImageTextGroupCarouselArrows>
                    </ImageTextGroupCarouselControls>
                </ImageTextGroupCarouselHeader>
            )}
            <div ref={moduleWidthRef}>
                <CarouselDrag leftArrowRef={leftArrowRef} rightArrowRef={rightArrowRef}>
                    <CarouselContentContainer numberOfSpots={numberOfSpots}>{children}</CarouselContentContainer>
                </CarouselDrag>
            </div>
        </ImageTextGroupCarouselContainer>
    );
};

const ImageTextGroupCarouselContainer = styled.div(({ theme }) => ({
    [`& ${StyledScrollBarContainer}`]: {
        marginBottom: 28,
        marginRight: theme.space.gutter,
        [mq('xl')]: {
            marginRight: `calc((100vw - 1600px)/2)`,
        },
    },
}));
const ImageTextGroupCarouselHeader = styled.div<{ titleLength?: number }>(({ titleLength }) => ({
    ...flexColumn,
    justifyContent: 'space-between',
    marginBottom: 20,

    [mq('sm')]: {
        ...((titleLength || 0) >= 18
            ? {
                  ...flexColumn,
              }
            : {
                  ...flexRow,
                  alignItems: 'center',
              }),
    },
    [mq('md')]: {
        ...flexRow,
        alignItems: 'center',
    },
}));

const ImageTextGroupCarouselTitle = styled.h4(({ theme }) => ({
    fontFamily: brandLeg ? theme.fontFamilies.heading : theme.fontFamilies.regular,
}));

const ImageTextGroupCarouselControls = styled(Flex)({
    [mq(0, 'md')]: {
        justifyContent: 'flex-end',
    },
});

const ImageTextGroupCarouselArrows = styled.div<{ numberOfSpots?: number }>(({ numberOfSpots, theme }) => ({
    textAlign: 'right',
    display: 'flex',
    flexDirection: 'row',
    gap: 10,
    marginLeft: theme.space[7],
    ...((numberOfSpots || 0) <= 2 && {
        [mq('md')]: {
            display: 'none',
            marginLeft: 0,
        },
    }),
    ...((numberOfSpots || 0) === 3 && {
        [mq('lg')]: {
            display: 'none',
            marginLeft: 0,
        },
    }),
    ...((numberOfSpots || 0) === 4 && {
        [mq('xl')]: {
            display: 'none',
            marginLeft: 0,
        },
    }),
}));

// const CarouselBreakoutContainer = styled.div(({ theme }) => ({
//     marginRight: `calc(-1* ${theme.space.gutter})`,
//     [mq('xl')]: {
//         marginRight: `calc(-1 *((100vw - 1600px)/2))`,
//     },
//     [`& ${StyledItemWrapper}`]: {
//         paddingRight: theme.space.gutter,
//         [mq('xl')]: {
//             paddingRight: `calc((100vw - 1600px)/2)`,
//         },
//     },
// }));

const CarouselContentContainer = styled.div<{ numberOfSpots?: number }>(({ numberOfSpots, theme }) => ({
    display: 'flex',
    gap: '20px',

    [`& ${ImageTextSpotHeader}`]: {
        fontSize: 24,
    },
    [`& ${ImageTextSpotText}`]: {
        fontSize: 14,
    },
    [`& ${ImageTextSpotContainer}`]: {
        ...flexColumn,
        minWidth: 370,
        marginBottom: 0,
        maxHeight: 'initial',
        ...(numberOfSpots === 2 && {
            [mq('md')]: {
                width: '50%',
                maxWidth: '50vw',
                minWidth: 'initial',
            },
        }),
        ...(numberOfSpots === 3 && {
            [mq('lg')]: {
                width: '33%',
                maxWidth: '33vw',
                minWidth: 'initial',
            },
        }),
        ...(numberOfSpots === 4 && {
            [mq('xl')]: {
                width: '25%',
                maxWidth: '25vw',
                minWidth: 'initial',
            },
        }),
    },
    [`& ${ImageContainerProductSpot}`]: {
        width: '100%',
        padding: '56px 56px 40px',
        aspectRatio: 'initial',
        height: 'auto',
        order: 'initial',
    },
    [`& ${ContentContainerProductSpot}`]: {
        padding: '0 32px 32px',
        width: '100%',
        alignItems: 'inherit',
        [`& ${ImageTextContentTextWrapper}`]: {
            alignItems: 'center',
            textAlign: 'center',
            width: '100%',
        },
        [`& ${TagContainer}`]: {
            justifyContent: 'center',
            width: '100%',
        },
        [`& ${ImageTextContentFlexWrapper}`]: {
            alignItems: 'inherit',
        },
        [`& ${ImageTextSpotHeader}`]: {
            textAlign: 'inherit',
        },
        [`& ${ImageTextSpotText}`]: {
            textAlign: 'inherit',
        },
    },
    [`& ${ImageContainerStorySpot}`]: {
        width: '100%',
        height: 'auto',
        order: 'initial',
    },
    [`& ${ContentContainerStorySpot}`]: {
        padding: '32px 24px',
        width: '100%',

        alignItems: 'inherit',
        [`& ${ImageTextContentTextWrapper}`]: {
            alignItems: 'inherit',
        },
        [`& ${ImageTextContentFlexWrapper}`]: {
            alignItems: 'inherit',
        },
        [`& ${ImageTextSpotHeader}`]: {
            textAlign: 'inherit',
        },
        [`& ${ImageTextSpotText}`]: {
            textAlign: 'inherit',
        },
        [`& ${ImageTextButton}`]: {
            minWidth: 'auto',
            alignSelf: 'flex-start',
        },
    },
    [`& ${ImageTextContentTextWrapper}`]: {
        height: '100%',
    },
    [`& ${ImageTextButton}`]: {
        // height: 'auto',
        minWidth: 'auto',
        // padding: 10,
        alignSelf: 'center',
    },
    // IND 11741
    // [`& ${ImageTextButtonText}`]: {
    //     display: 'none',
    // },
    // [`& ${ImageTextButtonIcon}`]: {
    //     display: 'initial',
    // },
    [`& ${TagItem}`]: {
        fontSize: theme.fontSizes.xxxxs,
        lineHeight: '13px',
    },
    [`& ${PriceContainerProductSpot}`]: {
        justifyContent: 'center',
        width: '100%',
    },
}));

const ArrowButton = styled(Button)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 45,
    height: 45,
    borderRadius: 80,
    backgroundColor: theme.colors.black,
    color: theme.colors.white,

    '&:hover': {
        backgroundColor: theme.colors.grey70,
    },

    svg: {
        fill: theme.colors.white,
        color: theme.colors.white,
    },

    '&:disabled': {
        backgroundColor: theme.colors.grey10,
        color: theme.colors.grey30,
        svg: {
            fill: theme.colors.grey30,
            color: theme.colors.grey30,
        },
    },
}));
