import { StyledButtonProps } from '$components/elements/button';

export const getButtonVariant = (variantClass?: string): StyledButtonProps['variant'] => {
    switch (variantClass) {
        case 'btn btn--primary':
            return 'primary';
        case 'btn btn--secondary':
            return 'secondary';
        case 'btn btn--tertiary':
            return 'tertiary';

        default:
            return 'primary';
    }
};
