import React, { FC, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { IContentSpot, IRaptorResponse, IRecommendationParameters } from '$models';
import { useSWR } from '$hooks';
import { API_URL, getRaptorCookieId } from '$lib/helpers';
import { ProductCarouselSpot } from './product-carousel-spot';
import { Loading } from '$components/elements/loading/loading';
import { useBasket, useSite } from '~/store';
import { raptorBasketFeedType, RaptorParameterKeys } from '$constants';
import { getCookie } from '$lib/helpers/cookie.helper';
import { Flex } from '$components/layouts';
import { SpotLayout } from './components/spot-layout';

// TODO Discuss with BE about a more proper solution IND-7961
// Right now we are targeting feedtype to inject basket line id's into raptor service

type Props = {
    recommendationHeader: string;
    recommendationSubHeader: string;
    recommendationServiceRequest: {
        raptorFeedType: string;
        minimumNumberOfProducts?: number;
        maximumNumberOfProducts?: number;
        parameters: IRecommendationParameters[];
    };
} & IContentSpot;

export const RaptorSpot: FC<Props> = (props: Props) => {
    const { recommendationHeader: heading, recommendationSubHeader: subHeading, recommendationServiceRequest } = props;
    const { basket } = useBasket();
    const [raptorUserId, setRaptorUserId] = useState<string>();
    const { raptorId } = useSite();

    useEffect(() => {
        if (raptorId && !raptorUserId) {
            const userId = getCookie(getRaptorCookieId());
            if (userId) {
                setRaptorUserId(userId);
            }
        }
    }, [raptorId]);

    const { data, isLoading } = useSWR<IRaptorResponse>(
        API_URL +
            `/scom/api/raptor/${recommendationServiceRequest.raptorFeedType}/${
                recommendationServiceRequest.minimumNumberOfProducts
            }/${recommendationServiceRequest.maximumNumberOfProducts}${
                recommendationServiceRequest.parameters
                    ? '?' +
                      Object.keys(recommendationServiceRequest.parameters)
                          .map(
                              (x) =>
                                  `parameters.${x}=${
                                      x === RaptorParameterKeys.ProductId &&
                                      recommendationServiceRequest.raptorFeedType === raptorBasketFeedType
                                          ? basket?.lines?.map((line) => line.product?.itemNumber).join(',')
                                          : x === RaptorParameterKeys.UserId && raptorUserId
                                          ? raptorUserId
                                          : // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                            recommendationServiceRequest.parameters[x as any]
                                  }`
                          )
                          .join('&')
                    : ''
            }`
    );

    return (
        <SpotLayout ignoreVisibilityClass eagerLoad={props.eagerLoad}>
            {isLoading && (
                <LoadingContainer alignItems="center">
                    <Loading />
                </LoadingContainer>
            )}
            {!isLoading &&
                !!data?.variants?.length &&
                (!recommendationServiceRequest.minimumNumberOfProducts ||
                    recommendationServiceRequest.minimumNumberOfProducts <= data.variants.length) && (
                    <ProductCarouselSpot
                        ignoreVisibilityClass
                        heading={heading}
                        subHeading={subHeading}
                        products={data?.variants}
                        trackingInfo={{
                            feedType:
                                data?.feedType === recommendationServiceRequest?.raptorFeedType
                                    ? data?.feedType
                                    : undefined,
                            recommendationHeader: heading,
                        }}
                    />
                )}
        </SpotLayout>
    );
};

const LoadingContainer = styled(Flex)({
    minHeight: '32rem',
});
