import React, { FC } from 'react';
import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';
import { Flex } from '$components/layouts';
import { Text } from '$components/elements/text';
import { SpotLayout, SpotLayoutProps } from './spot-layout';
import { SpotHeading as SpotLayoutHeading, SpotHeadingProps } from './spot-heading';
import { getColor } from '$lib/get-color';
import { SpotSpacer } from '$components/elements/spot-spacer';
import { RichText } from '$components/elements/rich-text';

export type SpotLayoutWithHeadingProps = {
    heading?: string;
    subHeading?: string;
    textColor?: string;
    visibilityClass?: string;
};

type Props = SpotLayoutWithHeadingProps & SpotLayoutProps & SpotHeadingProps;

export const SpotLayoutWithHeading: FC<Props> = ({
    children,
    heading,
    subHeading,
    textColor,
    topSpot,
    ...spotLayoutProps
}) => {
    const theme = useTheme();

    return (
        <SpotLayoutWithHeadingContainer {...spotLayoutProps}>
            <SpotHeadingContainer column alignItems="center">
                {!heading?.trim() && !subHeading?.trim() && !children ? (
                    <SpotSpacer divider />
                ) : (
                    <>
                        {!!heading && (
                            <SpotLayoutHeading
                                variant="display3"
                                color={getColor(theme, textColor)}
                                topSpot={topSpot}
                                noSpacing
                                center
                            >
                                {heading}
                            </SpotLayoutHeading>
                        )}
                        {!!subHeading && (
                            <SpotLayoutText variant="bodyLarge" color={getColor(theme, textColor)}>
                                <RichText
                                    text={subHeading}
                                    textColor={getColor(theme, textColor)}
                                    css={{ padding: 0 }}
                                />
                            </SpotLayoutText>
                        )}
                    </>
                )}
            </SpotHeadingContainer>
            <SpotLayoutContainer>{children}</SpotLayoutContainer>
        </SpotLayoutWithHeadingContainer>
    );
};

const SpotLayoutWithHeadingContainer = styled(SpotLayout)({});

const SpotHeadingContainer = styled(Flex)(({ theme: { space } }) => ({
    marginTop: space[8],
}));

const SpotLayoutContainer = styled.div(({ theme: { space } }) => ({
    marginTop: space[8],
    display: 'inherit',
}));

const SpotLayoutText = styled(Text)(({ theme: { space } }) => ({
    marginTop: space[3],
    opacity: 0.6,
    textAlign: 'center',
}));
