import React, { FC } from 'react';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { FeatureSpotBase, FeatureSpotBaseProps } from './feature-spot-base';
import { Button } from '$components/elements/button';
import { IconReview } from '$components/elements/icon-review';
import { Link } from '$components/elements/link';
import { Text } from '$components/elements/text';
import { getColor } from '$lib/get-color';
import { getButtonVariant } from '$lib/get-button';
import { SpotHeadingProps } from '$components/spots/components/spot-heading';
import { TextSpotHeading } from '$components/spots/components/text-spot-heading';
import { CircleMiniSpot } from './components/circle-mini-spot';
import { mq } from '$lib/helpers';

export type CampaignFeaturedLinkSpotProps = unknown;

type Props = CampaignFeaturedLinkSpotProps & FeatureSpotBaseProps & SpotHeadingProps;

export const CampaignFeaturedLinkSpot: FC<Props> = ({ topSpot, ...props }) => {
    const theme = useTheme();

    return (
        <CampaignFeaturedLinkSpotContainer {...props} hideLink={props.buttonText ? true : false}>
            <CircleMiniSpot {...props}>
                {props.hearts ? (
                    <IconReview
                        score={props.hearts}
                        numberOfIcons={props.hearts}
                        size={16}
                        color={getColor(theme, props.heartsColor)}
                    />
                ) : (
                    <></>
                )}
                <Text color={getColor(theme, props.textColor)} fontStyle="italic" center>
                    {props.superText}
                </Text>
                <TextSpotHeading color={getColor(theme, props.textColor)} topSpot={topSpot} width={props.width} center>
                    {props.heading}
                </TextSpotHeading>
                {props.teaser && (
                    <Text color={getColor(theme, props.textColor)} variant="bodyLarge" p center>
                        {props.teaser}
                    </Text>
                )}
                {props.buttonText && (
                    <Button
                        as={Link}
                        applyTextStyling={false}
                        variant={getButtonVariant(props.buttonType)}
                        href={props.spotLink?.url}
                        target={props.spotLink?.target}
                    >
                        {props.buttonText}
                    </Button>
                )}
            </CircleMiniSpot>
        </CampaignFeaturedLinkSpotContainer>
    );
};

const CampaignFeaturedLinkSpotContainer = styled(FeatureSpotBase)(({ circleHeading, circleBackgroundColor }) => ({
    [mq('md', 'lg')]: {
        paddingLeft: circleHeading || circleBackgroundColor ? 50 : 0,
        paddingRight: circleHeading || circleBackgroundColor ? 50 : 0,
    },

    [mq(0, 'md')]: {
        paddingLeft: circleHeading ? 40 : 0,
        paddingRight: circleHeading ? 40 : 0,
    },
}));
