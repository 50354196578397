import React, { FC, useMemo } from 'react';
import styled from '@emotion/styled';
import { mq } from '$lib/helpers';
import { ImageTextSpot, ImageTextSpotContainer } from '../image-text-spot';
import { ImageTextGroupSpotModel, ImageTextSpotStyle, ImageTextSpotType } from '$models/spots-types';
import {
    ImageTextButton,
    ImageTextContentFlexWrapper,
    ImageTextContentTextWrapper,
    ImageTextSpotHeader,
    ImageTextSpotText,
} from '../image-text-spot/shared/styles';
import { flexColumn, flexRow } from '$lib/style-helpers';
import {
    ContentContainerProductSpot,
    ImageContainerProductSpot,
    PriceContainerProductSpot,
} from '../image-text-spot/variants/product-spot';
import { ContentContainerStorySpot, ImageContainerStorySpot } from '../image-text-spot/variants/story-spot';
import { ImageTextGroupCarousel } from './image-text-group-carousel';
import { Link } from '$components/elements/link';
import { TagContainer, TagItem } from '../image-text-spot/shared/tags';

const productImageHeightHelper = (numberOfSpots?: number) => {
    if (!numberOfSpots) {
        return undefined;
    }
    if (numberOfSpots === 1) {
        return 320;
    }
    if (numberOfSpots === 2) {
        return 200;
    }
    if (
        numberOfSpots === 3 ||
        numberOfSpots === 5 ||
        numberOfSpots === 6 ||
        numberOfSpots === 9 ||
        numberOfSpots === 15
    ) {
        return 180;
    }
    return 160;
};

const renderRankingContent = (rank: number) => <RankingContainer>{`#${rank}`}</RankingContainer>;

// Grouping spot that wraps ImageTextSpots in a grid or a slider
// ImageTextSpot has it own baseline styling for when it's alone, either as hero or content
// The grouping spot handles all the styling overwrites that is needed when the spot is in grid or slider
export const ImageTextGroupSpot: FC<ImageTextGroupSpotModel> = (props) => {
    const childHasTagData = useMemo(
        () => props.imageAndTextSpots?.some((spot) => !!spot.tags),
        [props.imageAndTextSpots]
    );
    const childWithProductStyle = useMemo(
        () => props.imageAndTextSpots?.some((spot) => spot.style === 'Product'),
        [props.imageAndTextSpots]
    );
    const childWithStoryStyle = useMemo(
        () => props.imageAndTextSpots?.some((spot) => spot.style === 'Story'),
        [props.imageAndTextSpots]
    );

    const childWithProductBgImage = useMemo(
        () => props.imageAndTextSpots?.some((spot) => spot.style === 'Product' && spot.showBackGroundImage),
        [props.imageAndTextSpots]
    );
    const titleLength = props.title?.length;

    return (
        <ImageTextGroupSpotContainer
            topSpacing={props.topSpot ? false : props.sliderMode || !!props.title || !!props.link}
            hasBothSpotTypes={childWithProductStyle && childWithStoryStyle}
            childWithProductBgImage={childWithProductBgImage}
            centerStorySpot={props.centerStorySpot}
        >
            {props.sliderMode && (
                <ImageTextGroupCarousel
                    title={props.title}
                    cta={props.callToAction}
                    link={props.link}
                    numberOfSpots={props.imageAndTextSpots?.length}
                    isTopSpot={props.topSpot}
                >
                    {props.imageAndTextSpots?.map((spot, index) => (
                        <ImageTextSpot
                            key={index}
                            {...spot}
                            frameMarkings={(props.imageAndTextSpots?.length || 0) > 1 ? undefined : spot.frameMarkings}
                            hasFixedTagHeight={childHasTagData}
                            productImageHeight={productImageHeightHelper(props.imageAndTextSpots?.length)}
                            imageTopLeftContent={props.showRank ? renderRankingContent(index + 1) : undefined}
                            theme={props.theme ? props.theme : spot.theme}
                        />
                    ))}
                </ImageTextGroupCarousel>
            )}
            {!props.sliderMode && (
                <>
                    {(!!props.title || (!!props.callToAction && props.link)) && (
                        <ContentGridHeader titleLength={titleLength}>
                            <ContentGridTitle>{props.title}</ContentGridTitle>

                            {props.link && !!props.callToAction && (
                                <Link href={props.link.url} target={props.link.target} alwaysShowUnderline>
                                    {props.callToAction}
                                </Link>
                            )}
                        </ContentGridHeader>
                    )}
                    <ContentGridContainer
                        numberOfSpots={props.imageAndTextSpots?.length}
                        isProductContainer={props.imageAndTextSpots?.some((spot) => spot.style === 'Product')}
                    >
                        {props.imageAndTextSpots?.map((spot, index) => (
                            <ContentContainer
                                key={index}
                                numberOfSpots={props.imageAndTextSpots?.length}
                                hasBackgroundImage={spot.showBackGroundImage}
                            >
                                <ImageTextSpot
                                    {...spot}
                                    frameMarkings={
                                        (props.imageAndTextSpots?.length || 0) > 1 ? undefined : spot.frameMarkings
                                    }
                                    hasFixedTagHeight={childHasTagData}
                                    productImageHeight={productImageHeightHelper(props.imageAndTextSpots?.length)}
                                    imageTopLeftContent={props.showRank ? renderRankingContent(index + 1) : undefined}
                                    theme={props.theme ? props.theme : spot.theme}
                                />
                            </ContentContainer>
                        ))}
                    </ContentGridContainer>
                </>
            )}
        </ImageTextGroupSpotContainer>
    );
};

const spotGridHelper = (numberOfSpots = 0, breakpoint?: 'md' | 'lg') => {
    switch (breakpoint) {
        case 'md': {
            if (numberOfSpots <= 2) {
                return { gridTemplateColumns: `repeat(${numberOfSpots}, 1fr)` };
            }
            return { gridTemplateColumns: `repeat(2, 1fr)` };
        }

        case 'lg': {
            if (numberOfSpots <= 4) {
                return { gridTemplateColumns: `repeat(${numberOfSpots}, 1fr)` };
            }
            // This part is kinda hardcodet. You could probably find a algorithm, but is it really worth it
            // Only with these four numbers does it make sense the grid is 3 instead of 4.
            // Of course there are numbers above 15 that should change, but there should never be a spot with that many elements
            if (numberOfSpots === 5 || numberOfSpots === 6 || numberOfSpots === 9 || numberOfSpots === 15) {
                return { gridTemplateColumns: `repeat(3, 1fr)` };
            }
            return { gridTemplateColumns: `repeat(4, 1fr)` };
        }
        default:
            return { gridTemplateColumns: `1fr` };
    }
};

const spotHeaderHelper = (numberOfSpots = 0) => {
    if (numberOfSpots < 2) {
        return undefined;
    }
    if (
        numberOfSpots === 3 ||
        numberOfSpots === 5 ||
        numberOfSpots === 6 ||
        numberOfSpots === 9 ||
        numberOfSpots === 15
    ) {
        return { fontSize: 32 };
    }
    if (numberOfSpots >= 4) {
        return { fontSize: 24 };
    }
};

const spotTextHelper = (numberOfSpots = 0) => {
    if (numberOfSpots > 2) {
        return { fontSize: 14 };
    }
    return undefined;
};

const breakPoint = 'frame';

const ImageTextGroupSpotContainer = styled.div<{
    spotType?: ImageTextSpotType;
    spotStyle?: ImageTextSpotStyle;
    topSpacing?: boolean;
    hasBothSpotTypes?: boolean;
    childWithProductBgImage?: boolean;
    centerStorySpot?: boolean;
}>(({ theme, topSpacing, hasBothSpotTypes, childWithProductBgImage, centerStorySpot }) => ({
    position: 'relative',
    minWidth: 0,
    gridColumn: '1 / -1',
    marginBottom: theme.space[5],
    ...(topSpacing && {
        marginTop: 48,
    }),
    [mq(breakPoint)]: {},
    ...(hasBothSpotTypes && {
        [`& ${ImageTextContentTextWrapper}`]: {
            width: '100%',
        },
        [`& ${ImageTextButton}`]: {
            alignSelf: 'initial',
        },
    }),
    [`& ${ImageContainerProductSpot}`]: {
        ...(childWithProductBgImage && {
            marginBottom: 32,
        }),
    },
    ...(centerStorySpot && {
        [`& ${ImageTextSpotContainer}`]: {
            [`& ${ContentContainerStorySpot}`]: {
                alignItems: 'center',
                textAlign: 'center',

                [`& ${ContentGridContainer}`]: {
                    alignItems: 'center',
                },
                [`& ${ImageTextButton}`]: {
                    alignSelf: 'center',
                },
                [mq(0, 'md')]: {
                    ...(hasBothSpotTypes && {
                        [`& ${ImageTextContentTextWrapper}`]: {
                            alignItems: 'center',
                        },
                    }),
                },
                [mq('lg')]: {
                    ...(hasBothSpotTypes && {
                        [`& ${ImageTextButton}`]: {
                            alignSelf: 'initial',
                        },
                        [`& ${ContentGridContainer}`]: {
                            alignItems: 'initial',
                        },
                    }),
                },
            },
        },
    }),
}));

const ContentGridContainer = styled.div<{ numberOfSpots?: number; isProductContainer?: boolean }>(
    ({ numberOfSpots = 0, isProductContainer, theme }) => ({
        display: 'grid',
        gap: 20,
        ...spotGridHelper(numberOfSpots),
        [mq('md')]: {
            ...spotGridHelper(numberOfSpots, 'md'),
        },
        [mq('lg')]: {
            ...spotGridHelper(numberOfSpots, 'lg'),
        },

        ...(numberOfSpots > 1 && {
            [`& ${TagItem}`]: {
                fontSize: theme.fontSizes.xxxxs,
                lineHeight: '13px',
            },
        }),
        ...(numberOfSpots > 2 && {
            [`& ${ImageTextButton}`]: {
                // height: 'auto',
                minWidth: 'auto',
                // padding: 10,
                alignSelf: 'center',
                // [mq('lg')]: {
                //     padding: numberOfSpots > 3 ? 10 : 14,
                // },
            },
            // IND 11741
            // [`& ${ImageTextButtonText}`]: {
            //     display: 'none',
            // },
            // [`& ${ImageTextButtonIcon}`]: {
            //     display: 'initial',
            // },
        }),
        [`& ${ImageTextSpotContainer}`]: {
            flex: '1 1 auto',
            marginBottom: 0,

            ...((numberOfSpots || 0) > 1 && {
                maxHeight: 'initial',
            }),
            ...((numberOfSpots || 0) > 2 && {
                ...flexColumn,
            }),
            ...((numberOfSpots || 0) === 2 && {
                ...flexColumn,
                ...(isProductContainer
                    ? {
                          [mq('lg')]: {
                              ...flexRow,
                          },
                      }
                    : {
                          [mq('xl')]: {
                              ...flexRow,
                          },
                      }),
            }),
        },
        [`& ${ImageTextSpotHeader}`]: {
            [mq('md')]: {
                fontSize: 24,
            },
            [mq('lg')]: {
                ...spotHeaderHelper(numberOfSpots),
            },
            marginBottom: 12,
        },
        [`& ${ImageTextSpotText}`]: {
            ...spotTextHelper(numberOfSpots),
        },
        [`& ${ImageContainerProductSpot}`]: {
            ...((numberOfSpots || 0) > 2 && {
                width: '100%',
                padding: '64px',
                aspectRatio: 'initial',
                height: 'auto',
            }),
            ...((numberOfSpots || 0) > 3 && {
                padding: '56px',
            }),
            ...((numberOfSpots || 0) === 2 && {
                width: '43%',
                padding: '64px',
                aspectRatio: 'initial',
                height: 'auto',
                [mq(0, 'lg')]: {
                    width: '100%',
                },
            }),
            order: 'initial',
        },
        [`& ${ImageContainerStorySpot}`]: {
            ...((numberOfSpots || 0) > 1 && {
                height: 'auto',
            }),
            ...((numberOfSpots || 0) > 2 && {
                width: '100%',
            }),
            ...((numberOfSpots || 0) === 2 && {
                [mq(0, 'xl')]: {
                    width: '100%',
                },
            }),
            order: 'initial',
        },
        [`& ${ContentContainerStorySpot}`]: {
            padding: '48px 48px 40px',
            alignItems: 'inherit',

            ...((numberOfSpots || 0) > 2 && {
                padding: '32px 48px',
                width: '100%',
                [`& ${ImageTextContentTextWrapper}`]: {
                    alignItems: 'center',
                    textAlign: 'center',
                    width: '100%',
                },
                [`& ${TagContainer}`]: {
                    justifyContent: 'center',
                    width: '100%',
                },
                [`& ${ImageTextContentFlexWrapper}`]: {
                    alignItems: 'inherit',
                },
                [`& ${ImageTextSpotHeader}`]: {
                    textAlign: 'inherit',
                },
                [`& ${ImageTextSpotText}`]: {
                    textAlign: 'inherit',
                },
            }),
            ...((numberOfSpots || 0) > 3 && {
                padding: '32px 32px',
            }),
            ...((numberOfSpots || 0) === 2 && {
                [mq(0, 'xl')]: {
                    width: '100%',
                },
            }),
        },
        [`& ${ContentContainerProductSpot}`]: {
            paddingLeft: 24,
            paddingRight: 24,
            paddingBottom: 40,
            ...((numberOfSpots || 0) > 2 && {
                paddingLeft: 64,
                paddingRight: 64,
                paddingBottom: 40,
                width: '100%',
                [mq(0, 'lg')]: {
                    paddingLeft: 32,
                    paddingRight: 32,
                    paddingBottom: 32,
                },
                [`& ${ImageTextContentFlexWrapper}`]: {
                    alignItems: 'inherit',
                },
                [`& ${ImageTextContentTextWrapper}`]: {
                    alignItems: 'center',
                    textAlign: 'center',
                    width: '100%',
                },
                [`& ${PriceContainerProductSpot}`]: {
                    justifyContent: 'center',
                    width: '100%',
                },
                [`& ${ImageTextSpotHeader}`]: {
                    textAlign: 'inherit',
                },
                [`& ${ImageTextSpotText}`]: {
                    textAlign: 'inherit',
                },
            }),
            ...((numberOfSpots || 0) > 3 && {
                paddingLeft: 32,
                paddingRight: 32,
                paddingBottom: 32,
            }),
            ...((numberOfSpots || 0) === 2 && {
                width: '57%',
                paddingLeft: 24,
                paddingRight: 64,
                paddingBottom: 64,

                [mq(0, 'xl')]: {
                    width: '100%',
                },
                [mq(0, 'lg')]: {
                    paddingLeft: 64,
                    paddingRight: 64,
                    paddingBottom: 40,
                    [`& ${ImageTextContentTextWrapper}`]: {
                        alignItems: 'center',
                        textAlign: 'center',
                        width: '100%',
                    },
                    [`& ${PriceContainerProductSpot}`]: {
                        justifyContent: 'center',
                        width: '100%',
                    },
                },
            }),
            [`& ${TagContainer}`]: {
                ...((numberOfSpots || 0) > 2 && {
                    justifyContent: 'center',
                }),
            },
        },
        [`& ${ImageTextContentTextWrapper}`]: {
            wordBreak: 'break-word',
            ...((numberOfSpots || 0) > 1 && {
                height: '100%',
            }),
        },
    })
);

const ContentGridHeader = styled.div<{ titleLength?: number }>(({ titleLength }) => ({
    ...flexColumn,
    justifyContent: 'space-between',
    marginBottom: 20,
    alignItems: 'flex-end',
    [`& ${ContentGridTitle}`]: {
        alignSelf: 'flex-start',
    },

    [mq('sm')]: {
        ...((titleLength || 0) >= 18
            ? {
                  ...flexColumn,
              }
            : {
                  ...flexRow,
                  alignItems: 'center',
                  [`& ${ContentGridTitle}`]: {
                      alignSelf: 'center',
                  },
              }),
    },
    [mq('md')]: {
        ...flexRow,
        alignItems: 'center',
        [`& ${ContentGridTitle}`]: {
            alignSelf: 'center',
        },
    },
}));

const ContentGridTitle = styled.h4(() => ({}));

const ContentContainer = styled.div<{
    numberOfSpots?: number;
    hasBackgroundImage?: boolean;
}>(({ numberOfSpots = 0, hasBackgroundImage }) => ({
    display: 'flex',
    [`& ${ContentContainerProductSpot}`]: {
        ...(hasBackgroundImage
            ? {
                  paddingTop: 32,
              }
            : {
                  paddingTop: 0,
              }),
        [mq(breakPoint)]: {
            ...(hasBackgroundImage
                ? {
                      paddingTop: 32,
                  }
                : {
                      paddingTop: numberOfSpots > 2 ? 0 : 64,
                  }),
        },
    },
}));

const RankingContainer = styled.div(({ theme }) => ({
    color: theme.colors.black,
    backgroundColor: theme.colors.white,
    padding: 20,
    boxShadow: `0px 5px 20px rgba(0, 0, 0, 0.12)`,
    borderRadius: 200,
    fontSize: 18,
    fontWeight: 500,
}));
