import React, { FC } from 'react';
import { SpotLayoutWithHeading } from './components/spot-layout-with-heading';

type Props = {
    text: string;
    subText: string;
};

export const HeadingSpot: FC<Props> = (props: Props) => {
    return <SpotLayoutWithHeading {...props} heading={props.text} subHeading={props.subText} />;
};
