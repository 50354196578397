import React, { FC } from 'react';
import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';
import { Text } from '$components/elements/text';
import { getColor } from '$lib/get-color';
import { CampaignSpotLayout, CampaignSpotLayoutProps } from './components/campaign-spot-layout';
import { ContentImage, ContentImageProps } from './components/content-image';
import { TextSpotHeading } from '$components/spots/components/text-spot-heading';
import { SpotHeadingProps } from '$components/spots/components/spot-heading';

export type CampaignImageSpotProps = {
    heading?: string;
    teaser?: string;
    textColor?: string;
};

type Props = CampaignImageSpotProps & CampaignSpotLayoutProps & ContentImageProps & SpotHeadingProps;

export const CampaignImageSpot: FC<Props> = ({ topSpot, ...props }) => {
    const theme = useTheme();

    return (
        <CampaignImageSpotContainer {...props} hideBackgroundImage>
            <ContentImage {...props} small />
            <CampaignImageSpotHeading
                color={getColor(theme, props.textColor)}
                variant="display3"
                center
                topSpot={topSpot}
                width={props.width}
            >
                {props.heading}
            </CampaignImageSpotHeading>
            {props.teaser && (
                <Text color={getColor(theme, props.textColor)} variant="bodyLarge" center>
                    {props.teaser}
                </Text>
            )}
        </CampaignImageSpotContainer>
    );
};

const CampaignImageSpotContainer = styled(CampaignSpotLayout)();

const CampaignImageSpotHeading = styled(TextSpotHeading)(({ theme }) => ({
    marginTop: theme.space[2],
}));
