import { FC, useMemo } from 'react';
import { useTheme } from '@emotion/react';
import { Image } from '$components/elements/image';
import { IImageResource } from '$models';

export type ContentImageProps = {
    contentImage?: IImageResource;
    small?: boolean;
    eagerLoad?: boolean;
};

type Props = ContentImageProps;

export const ContentImage: FC<Props> = ({ contentImage, small, eagerLoad }) => {
    const { spots } = useTheme();
    const width = useMemo(() => (small ? spots.campaignImageWidth : spots.featureImageWidth), [small, spots]);
    const height = useMemo(
        () => width * (small ? spots.campaignImageAspectRatio : spots.featureimageAspectRatio),
        [small, width]
    );

    return contentImage?.url ? (
        <Image
            priority={eagerLoad}
            src={contentImage?.url ?? ''}
            alt={contentImage?.altText}
            width={width}
            height={height}
        />
    ) : (
        <></>
    );
};
