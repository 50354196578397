import React, { FC } from 'react';
import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';
import { ifProp, switchProp } from 'styled-tools';
import { getColor } from '$lib/get-color';
import { SpotLayout } from './components/spot-layout';
import { mq } from '$lib/helpers';
import { TextSpotHeading } from './components/text-spot-heading';
import { RichText } from '$components/elements/rich-text';
import { SpotSpacer } from '$components/elements/spot-spacer';

type Props = {
    heading: string;
    body: string;
    columnCount?: number;
    extraPadding: true;
    backgroundColor: string;
    textColor: string;
    height?: 'short' | 'average' | 'tall' | 'auto' | undefined;
    width?: 'small' | 'medium' | 'large' | 'full' | undefined;
};

export const TextSpot: FC<Props> = (props: Props) => {
    const theme = useTheme();

    return (
        <TextSpotLayout
            {...props}
            backgroundColor={props.backgroundColor ? getColor(theme, props.backgroundColor) : undefined}
        >
            {!props.heading && !props.body ? (
                <SpotSpacer />
            ) : (
                <>
                    {!!props.heading && (
                        <TextSpotHeading
                            width={props.width}
                            bottomMargin="2"
                            color={props.textColor ? getColor(theme, props.textColor) : undefined}
                            center
                        >
                            {props.heading}
                        </TextSpotHeading>
                    )}
                    <TextSpotRichText
                        text={props.body}
                        width={props.width}
                        columnCount={props.columnCount}
                        extraPadding={props.extraPadding}
                        textColor={props.textColor ? getColor(theme, props.textColor) : undefined}
                    />
                </>
            )}
        </TextSpotLayout>
    );
};

type WidthProp = Pick<Props, 'width'>;

const TextSpotLayout = styled(SpotLayout)<WidthProp>(({ theme: { space } }) => ({
    padding: `${space[5]} ${space[6]}`,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    minHeight: '70px',
    [mq(0, 'md')]: {
        minHeight: '40px',
    },
}));

const TextSpotRichText = styled(RichText)<WidthProp>(
    {
        whiteSpace: 'normal',
        overflow: 'hidden',
        lineHeight: 1.2,
        [mq(0, 'md')]: {
            padding: '1.25rem',
        },
    },

    ifProp(
        {
            width: 'full',
        },
        { maxWidth: 'none' }
    ),
    switchProp('width', {
        small: ({ theme: { mixins } }) => ({
            [mq('md')]: {
                ...mixins.useTextStyle('body'),
            },
        }),
        medium: ({ theme: { fontSizes, mixins } }) => ({
            fontSize: fontSizes.xs,
            [mq('sm')]: {
                fontSize: fontSizes.sm,
            },
            [mq('md')]: {
                fontSize: fontSizes.xxs,
            },
            [mq('ml')]: {
                fontSize: fontSizes.xs,
            },
            [mq('lg')]: {
                ...mixins.useTextStyle('body'),
            },
        }),
        large: ({ theme: { mixins } }) => ({
            [mq('md')]: {
                ...mixins.useTextStyle('body'),
            },
        }),
        default: ({ theme: { mixins } }) => ({
            [mq('md')]: {
                ...mixins.useTextStyle('body'),
            },
        }),
    })
);
