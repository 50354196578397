import React, { FC } from 'react';
import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';
import { FeatureSpotBase, FeatureSpotBaseProps } from './feature-spot-base';
import { ContentImage, ContentImageProps } from './components/content-image';
import { Text } from '$components/elements/text';
import { IconReview } from '$components/elements/icon-review';
import { getColor } from '$lib/get-color';
import { CircleMiniSpot } from './components/circle-mini-spot';
import { SpotHeadingProps } from '$components/spots/components/spot-heading';
import { TextSpotHeading } from '$components/spots/components/text-spot-heading';

export type CampaignFeaturedImageSpotProps = unknown;

type Props = CampaignFeaturedImageSpotProps & FeatureSpotBaseProps & ContentImageProps & SpotHeadingProps;

export const CampaignFeaturedImageSpot: FC<Props> = ({ topSpot, ...props }) => {
    const theme = useTheme();

    return (
        <CampaignFeaturedImageSpotContainer {...props} contentMargin>
            <CircleMiniSpot {...props}>
                <ContentImage {...props} />
            </CircleMiniSpot>
            {props.hearts ? (
                <StyledIconReview
                    score={props.hearts}
                    numberOfIcons={props.hearts}
                    size={16}
                    color={getColor(theme, props.heartsColor)}
                />
            ) : (
                <></>
            )}
            <TextSpotHeading
                color={getColor(theme, props.textColor)}
                bottomMargin="2"
                center
                topSpot={topSpot}
                width={props.width}
            >
                {props.heading}
            </TextSpotHeading>
            <Text as="p" color={getColor(theme, props.textColor)} variant="bodySmall" center>
                {props.spotText}
            </Text>
        </CampaignFeaturedImageSpotContainer>
    );
};

const CampaignFeaturedImageSpotContainer = styled(FeatureSpotBase)();

const StyledIconReview = styled(IconReview)(({ theme }) => ({
    marginTop: theme.space[6],
}));
