import React, { FC, memo, useState, useRef } from 'react';
import styled from '@emotion/styled';
import { IYoutubePlayerRef, YoutubePlayer } from './youtube-player';
import { VideoCurtain } from './video-curtain/video-curtain';

export type IVideoProps = {
    autoplay?: boolean;
    loop?: boolean;
    playInline?: boolean;
    muted?: boolean;
    onReady?: () => void;
    youtubeId?: string;
    thumbnailUrl?: string;
    thumbnailTitle?: string;
};

export const YoutubeVideo: FC<IVideoProps> = memo(
    ({
        autoplay = true,
        muted = false,
        loop = false,
        playInline = true,
        onReady,
        youtubeId,
        thumbnailUrl,
        thumbnailTitle,
    }: IVideoProps): JSX.Element => {
        const [isMuted] = useState(muted);
        const [disableThumbnail, setDisableThumbnail] = useState(false);
        const [isReady, setIsReady] = useState(false);

        const videoPlayerRef = useRef<HTMLVideoElement | IYoutubePlayerRef>(null);

        loop = loop || autoplay;
        playInline = autoplay || playInline;

        const getThumbnail = (): string | undefined => {
            if (thumbnailUrl) {
                return thumbnailUrl;
            }

            if (youtubeId) {
                return `https://img.youtube.com/vi/${youtubeId}/maxresdefault.jpg`;
            }

            return undefined;
        };

        const play = () => {
            setIsReady(true);
            setDisableThumbnail(true);
        };

        return (
            <StyledVideoContainer isReady={isReady}>
                {youtubeId && isReady && (
                    <YoutubePlayer
                        autoPlay={autoplay}
                        muted={isMuted}
                        loop={loop}
                        playInline={playInline}
                        ref={videoPlayerRef}
                        youtubeVideoId={youtubeId}
                        onReady={onReady}
                    />
                )}
                {!disableThumbnail && (
                    <VideoCurtain
                        onClick={play}
                        showIcon={!isReady}
                        thumbnail={getThumbnail()}
                        title={thumbnailTitle}
                    />
                )}
            </StyledVideoContainer>
        );
    }
);

const StyledVideoContainer = styled.div<{ isReady?: boolean }>(({ theme, isReady }) => ({
    paddingTop: '56.25%',
    position: 'relative',
    backgroundColor: theme.colors.white,
    width: '100%',
    height: '100%',

    '&:after': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: theme.colors.black,
        zIndex: -2,

        visibility: isReady ? 'hidden' : 'initial',
    },

    '& iframe': {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        height: '100%',
        width: '100%',
        border: 0,
        outline: 'none',
        backgroundColor: 'transparent',
        zIndex: 1,
    },
}));
