import React, { FC } from 'react';
import { IContentSpot, IRaptorResponse } from '$models';
import { ProductCarouselSpot } from './product-carousel-spot';
import { SpotLayout } from './components/spot-layout';

type Props = {
    recommendationHeader: string;
    recommendationSubHeader: string;
} & IRaptorResponse &
    IContentSpot;

export const RaptorSpotSSR: FC<Props> = (props: Props) => {
    const { recommendationHeader: heading, recommendationSubHeader: subHeading, variants, feedType, eagerLoad } = props;

    return (
        <SpotLayout ignoreVisibilityClass eagerLoad={eagerLoad}>
            {!!variants?.length && (
                <ProductCarouselSpot
                    eagerLoad={eagerLoad}
                    ignoreVisibilityClass
                    heading={heading}
                    subHeading={subHeading}
                    products={variants}
                    trackingInfo={{ feedType: feedType, recommendationHeader: heading }}
                />
            )}
        </SpotLayout>
    );
};
