import React, { FC } from 'react';
import dynamic from 'next/dynamic';

const NoSsrFrag: FC<React.PropsWithChildren<unknown>> = (props: React.PropsWithChildren<unknown>) => (
    <React.Fragment>{props.children}</React.Fragment>
);

export const NoSSR = dynamic(() => Promise.resolve(NoSsrFrag), {
    ssr: false,
});
