import React, { FC } from 'react';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { Text } from '$components/elements/text';
import { getColor } from '$lib/get-color';
import { CampaignSpotLayout, CampaignSpotLayoutProps } from './components/campaign-spot-layout';
import { SpotSocialLink, SpotSocialLinkProps } from '$components/spots/components/spot-social-link';
import { SpotHeadingProps } from '$components/spots/components/spot-heading';
import { TextSpotHeading } from '$components/spots/components/text-spot-heading';
import { IContentSpot } from '$models';

export type CampaignIconSpotProps = {
    heading?: string;
    teaser?: string;
    textColor?: string;
};

type Props = CampaignIconSpotProps & CampaignSpotLayoutProps & SpotSocialLinkProps & SpotHeadingProps & IContentSpot;

export const CampaignIconSpot: FC<Props> = ({ topSpot, ...props }) => {
    const theme = useTheme();

    return (
        <CampaignIconSpotContainer {...props}>
            <SpotSocialLink {...props} />
            <CampaignIconTextSpotHeading
                color={getColor(theme, props.textColor)}
                center
                topSpot={topSpot}
                width={props.width}
            >
                {props.heading}
            </CampaignIconTextSpotHeading>
            {props.teaser && (
                <Text color={getColor(theme, props.textColor)} variant="bodyLarge" center>
                    {props.teaser}
                </Text>
            )}
        </CampaignIconSpotContainer>
    );
};

const CampaignIconSpotContainer = styled(CampaignSpotLayout)();

const CampaignIconTextSpotHeading = styled(TextSpotHeading)(({ theme }) => ({
    marginTop: theme.space[2],
}));
