import styled from '@emotion/styled';
import { darken, lighten } from 'color2k';
import { SvgIcon } from '$components/elements/svg-icon';
import { ImageTextSpotStyle, ImageTextSpotType, SpotTheme } from '$models/spots-types';
import { mq } from '$lib/helpers';
import { flexColumn } from '$lib/style-helpers';
import { Button } from '$components/elements/button';

const breakPoint = 'frame';

export const FrameMarker = styled(SvgIcon)<{
    position: 'topleft' | 'topright' | 'bottomright';
    spotStyle: ImageTextSpotStyle;
}>(({ position, theme }) => {
    const mobileSpacing = theme.space[6];
    const desktopSpacing = theme.space[8];
    return {
        position: 'absolute',
        zIndex: 2,
        ...(position === 'topleft' ? { top: mobileSpacing, left: mobileSpacing } : {}),
        ...(position === 'topright' ? { top: mobileSpacing, right: mobileSpacing } : {}),
        ...(position === 'bottomright' ? { bottom: mobileSpacing, right: mobileSpacing } : {}),

        [mq(breakPoint)]: {
            ...(position === 'topleft' ? { top: desktopSpacing, left: desktopSpacing } : {}),
            ...(position === 'topright' ? { top: desktopSpacing, right: desktopSpacing } : {}),
            ...(position === 'bottomright' ? { bottom: desktopSpacing, right: desktopSpacing } : {}),
        },
    };
});

export const ImageTextSpotHeader = styled.h2<{
    spotType: ImageTextSpotType;
    spotStyle: ImageTextSpotStyle;
    smallSpacing?: boolean;
}>(({ spotType, smallSpacing, theme }) => ({
    all: 'unset',
    fontFamily: spotType === 'Hero' ? theme.mixins.useFontFamily('heading') : theme.mixins.useFontFamily('regular'),
    fontWeight: 700,
    marginBottom: theme.space[4],
    fontSize: '32px',
    lineHeight: '32px',
    ...(smallSpacing && {
        marginBottom: theme.space[4],
    }),

    [mq(breakPoint)]: {
        fontSize: `clamp(32px, 2vw + 1rem, ${spotType === 'Hero' ? '44px' : '32px'})`,
        lineHeight: 1.1,
        marginBottom: theme.space[6],

        ...(smallSpacing && {
            marginBottom: theme.space[4],
        }),
    },
}));

export const ImageTextSpotText = styled.p(() => ({
    marginBottom: 0,
    maxWidth: 520,
}));

export const ImageTextContentFlexWrapper = styled.div(() => ({
    ...flexColumn,
    height: '100%',
}));

export const ImageTextContentTextWrapper = styled.div<{ maxWidth: number }>(({ maxWidth }) => ({
    ...flexColumn,
    alignItems: 'flex-start',
    maxWidth: maxWidth,
}));

export const ImageTextButton = styled(Button)<{ spotTheme?: SpotTheme }>(({ spotTheme, theme }) => ({
    backgroundColor: spotTheme?.buttonColor,
    color: spotTheme?.buttonTextColor,
    marginTop: theme.space[6],

    [mq(breakPoint)]: {
        marginTop: theme.space[8],
    },

    '&:hover, &:focus': {
        backgroundColor: spotTheme?.buttonColor
            ? spotTheme.buttonColor === '#000000'
                ? lighten(spotTheme.buttonColor, 0.2)
                : darken(spotTheme.buttonColor, 0.2)
            : spotTheme?.buttonColor,
        color: spotTheme?.buttonTextColor,
    },
}));

export const ImageTextButtonText = styled.span({});

export const ImageTextButtonIcon = styled(SvgIcon)({
    display: 'none',
});
