import React, { FC, useCallback } from 'react';
import styled from '@emotion/styled';
import { flexCenter, flexColumn } from '$lib/style-helpers';
import { Image } from '$components/elements/image';
import { Link } from '$components/elements/link';
import { getImageUrl, mq } from '$lib/helpers';
import { ImageTextSpotModel } from '$models/spots-types';
import {
    FrameMarker,
    ImageTextButton,
    ImageTextButtonIcon,
    ImageTextButtonText,
    ImageTextContentFlexWrapper,
    ImageTextContentTextWrapper,
    ImageTextSpotHeader,
    ImageTextSpotText,
} from '../shared/styles';
import { TagContainer, Tags } from '../shared/tags';
import { Flex } from '$components/layouts';

type ImageTextSpotType = 'Hero' | 'Content';

type Props = {
    hasFixedTagHeight?: boolean;
    imageHeight?: number;
    imageTopLeftContent?: React.ReactNode;
} & ImageTextSpotModel;

export const ProductSpot: FC<Props> = (props) => {
    const {
        style,
        frameMarkings,
        image,
        product,
        type,
        imageHeight,
        imageTopLeftContent,
        tags,
        theme: spotTheme,
        hasFixedTagHeight,
        header,
        description,
        link,
        callToAction,
        showBackGroundImage,
        imagePlacement,
    } = props;

    const rightPositionImage = imagePlacement === 'Right';
    const spotImage = image?.url || product?.image;
    const spotTitle = header || product?.header;
    const spotDescription = description || product?.description;

    const renderMarkings = useCallback(() => {
        // In case it needs to be changeable in the future
        const withTagLine = false;
        if (!frameMarkings?.color) {
            return null;
        }
        return (
            <>
                <FrameMarker
                    svg="frame"
                    size={28}
                    spotStyle={style}
                    color={frameMarkings.color}
                    position="topleft"
                    rotate={-90}
                />
                <FrameMarker svg="frame" size={28} spotStyle={style} color={frameMarkings.color} position="topright" />
                {withTagLine ? (
                    <FrameMarker
                        svg="frameWithTagline"
                        width={145}
                        height={28}
                        spotStyle={style}
                        color={frameMarkings.color}
                        position="bottomright"
                    />
                ) : (
                    <FrameMarker
                        svg="frame"
                        size={28}
                        spotStyle={style}
                        color={frameMarkings.color}
                        position="bottomright"
                        rotate={90}
                    />
                )}
            </>
        );
    }, [frameMarkings, style]);

    return (
        <>
            <ImageContainerProductSpot
                rightPositionImage={rightPositionImage}
                backgroundImage={showBackGroundImage ? getImageUrl(spotTheme?.backgroundImage?.url || '') : undefined}
            >
                <ImageWrapper>
                    {!!spotImage && (
                        <Link
                            href={props.link?.url || product?.link}
                            target={props.link?.target}
                            aria-label={props.callToAction}
                        >
                            <DesktopImage
                                alt={image?.altText}
                                src={spotImage}
                                priority={type === 'Hero'}
                                height={imageHeight || 300}
                                width={imageHeight || 300}
                            />
                            <MobileImage
                                alt={image?.altText}
                                src={spotImage}
                                priority={type === 'Hero'}
                                height={180}
                                width={180}
                            />
                        </Link>
                    )}
                    {imageTopLeftContent && (
                        <ImageTopLeftContentWrapper>{imageTopLeftContent}</ImageTopLeftContentWrapper>
                    )}
                </ImageWrapper>
            </ImageContainerProductSpot>
            <ContentContainerProductSpot
                spotType={type}
                hasBackgroundImage={showBackGroundImage}
                leftPositionContent={rightPositionImage}
            >
                <ImageTextContentTextWrapper maxWidth={720}>
                    <ImageTextContentFlexWrapper>
                        <Tags
                            tags={tags}
                            color={spotTheme?.textColor}
                            withFixedHeight={hasFixedTagHeight}
                            hoverTextColor={spotTheme?.buttonTextColor}
                        />
                        {!!spotTitle && (
                            <ImageTextSpotHeader
                                as={type === 'Hero' ? 'h1' : 'h2'}
                                spotStyle={style}
                                spotType={type}
                                smallSpacing={!!product?.activePrice}
                            >
                                <Link
                                    href={props.link?.url || product?.link}
                                    target={props.link?.target}
                                    aria-label={props.callToAction}
                                    applyTextStyling={false}
                                >
                                    {spotTitle}
                                </Link>
                            </ImageTextSpotHeader>
                        )}
                        {!!product?.activePrice && (
                            <PriceContainerProductSpot>
                                <Flex gap={'extrasmall'}>
                                    <Price
                                        themeColor={
                                            product.inActivePrice
                                                ? spotTheme?.onSalePriceColor
                                                : spotTheme?.activePriceColor
                                        }
                                    >
                                        {product.activePrice}
                                    </Price>
                                </Flex>
                                {product.inActivePrice && (
                                    <InActivePriceContainer gap={'extrasmall'}>
                                        <InActivePrice themeColor={spotTheme?.inactivePriceColor}>
                                            {product.inActivePrice}
                                        </InActivePrice>
                                    </InActivePriceContainer>
                                )}
                            </PriceContainerProductSpot>
                        )}
                        <ImageTextSpotText>{spotDescription}</ImageTextSpotText>
                    </ImageTextContentFlexWrapper>
                    {(!!link?.url || !!product?.link || !!callToAction) && (
                        <ImageTextButton
                            spotTheme={spotTheme}
                            as={Link}
                            applyTextStyling={false}
                            variant="primary"
                            href={link?.url || product?.link}
                            target={link?.target}
                            aria-label={callToAction}
                        >
                            <ImageTextButtonText>{callToAction}</ImageTextButtonText>
                            <ImageTextButtonIcon
                                size={16}
                                color={spotTheme?.buttonTextColor}
                                aria-hidden="true"
                                rotate={180}
                                svg="arrowLeft"
                            />
                        </ImageTextButton>
                    )}
                </ImageTextContentTextWrapper>
            </ContentContainerProductSpot>
            {renderMarkings()}
        </>
    );
};

const breakPoint = 'frame';

export const ImageWrapper = styled.div(() => ({
    position: 'relative',
}));

export const ImageContainerProductSpot = styled.div<{ rightPositionImage?: boolean; backgroundImage?: string }>(
    ({ theme, rightPositionImage, backgroundImage }) => ({
        ...flexCenter,
        position: 'relative',
        width: '100%',
        padding: theme.space[10],

        [mq(breakPoint)]: {
            width: '50%',
            height: '100%',
            padding: '80px 0',
            aspectRatio: '3 / 2',
            ...(rightPositionImage && {
                order: 2,
            }),
        },

        ...(!!backgroundImage && {
            backgroundImage: `url("${backgroundImage}")`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
        }),
    })
);

export const ImageTopLeftContentWrapper = styled.div(() => ({
    position: 'absolute',
    top: -30,
    left: -20,
}));

export const ContentContainerProductSpot = styled.div<{
    spotType: ImageTextSpotType;
    leftPositionContent: boolean;
    hasBackgroundImage?: boolean;
}>(({ spotType, leftPositionContent, hasBackgroundImage }) => ({
    ...flexColumn,
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    paddingBottom: 40,
    paddingLeft: 32,
    paddingRight: 32,
    paddingTop: hasBackgroundImage ? 32 : 0,

    [`& ${ImageTextContentTextWrapper}`]: {
        alignItems: 'center',
    },
    [`& ${TagContainer}`]: {
        justifyContent: 'center',
    },

    [mq(breakPoint)]: {
        width: '50%',
        flex: 1,
        alignItems: 'flex-start',
        justifyContent: 'center',
        textAlign: 'left',
        paddingTop: 64,
        paddingLeft: spotType === 'Content' ? 48 : undefined,
        paddingRight: spotType === 'Content' ? 100 : 180,
        paddingBottom: spotType === 'Content' ? 48 : 70,

        [`& ${ImageTextContentTextWrapper}`]: {
            alignItems: 'flex-start',
        },

        [`& ${TagContainer}`]: {
            justifyContent: 'initial',
        },

        ...(leftPositionContent && {
            alignItems: 'flex-end',
            paddingRight: spotType === 'Content' ? 48 : undefined,
            paddingLeft: spotType === 'Content' ? 100 : 180,
            [`& ${ImageTextContentTextWrapper}`]: {
                alignItems: 'flex-end',
            },
            [`& ${ImageTextContentFlexWrapper}`]: {
                alignItems: 'flex-end',
            },
            [`& ${ImageTextSpotHeader}`]: {
                textAlign: 'right',
            },
            [`& ${ImageTextSpotText}`]: {
                textAlign: 'right',
            },
        }),
    },
}));

export const PriceContainerProductSpot = styled.div(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    marginBottom: theme.space[4],
    gap: 5,
    [mq(breakPoint)]: {
        justifyContent: 'flex-start',
    },
}));

const Price = styled.span<{ themeColor?: string }>(({ themeColor, theme }) => ({
    fontWeight: theme.fontWeights.medium,
    fontSize: theme.fontSizes.md,
    ...(!!themeColor && {
        color: themeColor,
    }),
}));

const InActivePriceContainer = styled(Flex)(({ theme }) => ({
    fontSize: theme.fontSizes.sm,
    color: theme.colors.primaryGrey,
}));

const InActivePrice = styled.div<{ themeColor?: string }>(({ themeColor }) => ({
    textDecoration: 'line-through',
    ...(!!themeColor && {
        color: themeColor,
    }),
}));

const MobileImage = styled(Image)({
    [mq('md')]: {
        display: 'none',
    },
});

const DesktopImage = styled(Image)({
    [mq(0, 'md')]: {
        display: 'none',
    },
});
