import React, { FC } from 'react';
import styled from '@emotion/styled';
import { CampaignSpotLayout, CampaignSpotLayoutProps } from './components/campaign-spot-layout';

type Props = CampaignSpotLayoutProps;

export const CampaignBackgroundImageSpot: FC<Props> = (props) => {
    return <CampaignBackgroundImageSpotContainer {...props} />;
};

const CampaignBackgroundImageSpotContainer = styled(CampaignSpotLayout)();
