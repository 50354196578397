import React, { FC } from 'react';
import styled from '@emotion/styled';
import { mq } from '$lib/helpers';

type Props = {
    divider?: boolean;
};

/**
 * This beauty is to accomodate the abuse from the editors
 * where they use empty spots to make space between spots.
 */
export const SpotSpacer: FC<Props> = ({ divider = false }) => (
    <Spacer divider={divider}>{divider && <Divider />}</Spacer>
);

const Spacer = styled.div<{ divider?: boolean }>(({ divider }) => ({
    position: 'relative',
    width: '100%',
    padding: divider ? '10px' : '20px',
    [mq('md')]: {
        padding: divider ? '25px' : '35px',
    },
}));

const Divider = styled.div(({ theme }) => ({
    position: 'absolute',
    left: 0,
    right: 0,
    height: '1px',
    background: theme.colors.borderColor,
    top: '50%',
    transform: 'translateY(-50%)',
}));
