import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import { switchProp } from 'styled-tools';
import { useTheme } from '@emotion/react';
import useEmblaCarousel, { EmblaOptionsType } from 'embla-carousel-react';
import { useInterval, useScrollbarWidth } from 'react-use';
import { BreakpointKeys } from '$theme';
import { key } from '~/utils';

import { Flex } from '$components/layouts';
import { merge, mq } from '$lib/helpers';
import { useBreakpoints } from '$hooks';
import { breakContainer, partialContainer } from '$lib/style-helpers';
import { SvgIcon } from '../svg-icon';

type Options = {
    slidesPerView: number;
    spaceBetween?: number;
    revealNextSlide?: boolean;
    navigation?: boolean;
    scrollbarWidth?: number;
};

type SlideOptions = {
    breakpoints?: {
        [T in BreakpointKeys]?: Options;
    };
} & Options;

type Variant = 'hero' | 'product';

type Props<T extends unknown> = {
    autoplay?: boolean;
    autoplaySpeed?: number;
    data: Array<T>;
    extraData?: unknown;
    initialSlide?: number;
    onCarouselItemClick?: (item: T) => void;
    onSlideChange?: (index: number) => void;
    keyExtractor: (x: T, index: number) => string;
    loop?: boolean;
    template: React.JSXElementConstructor<T>;
    variant?: Variant;
    pagination?: boolean;
    showPaginationBelow?: boolean;
    alwaysShowArrowButtons?: boolean;
} & SlideOptions;

let scrollThrottleTimerId: NodeJS.Timeout | undefined;

// TODO IND-8147: Create translated aria labels for carousel buttons
export const Carousel = <T extends unknown>({
    autoplay = false,
    autoplaySpeed = 3000,
    breakpoints,
    initialSlide = 0,
    onCarouselItemClick,
    onSlideChange,
    loop = false,
    variant,
    pagination = false,
    showPaginationBelow = false,
    revealNextSlide = false,
    slidesPerView = 1,
    spaceBetween = 0,
    navigation: initNavigation = false,
    alwaysShowArrowButtons = false,
    ...rest
}: Props<T>) => {
    const { data, keyExtractor, template: Template, extraData, ...props } = rest;
    const { breakpoint, listOfBreakpoints } = useBreakpoints();
    const theme = useTheme();
    const scrollbarWidth = useScrollbarWidth();

    const [backwards, setBackwards] = useState(false);
    const [slidesPerPage, setSlidesPerPage] = useState(slidesPerView);
    const [space, setSpace] = useState(spaceBetween);
    const [emblaOptions, setEmblaOptions] = useState<Partial<EmblaOptionsType>>({
        align: 'start',
        containScroll: 'trimSnaps',
        loop,
        slidesToScroll: slidesPerPage,
        skipSnaps: true,
    });
    const [navigation, setNavigation] = useState(initNavigation);
    const [hover, setHover] = useState(false);
    const [dragging, setDragging] = useState(false);
    const [prevBtnEnabled, setPrevBtnEnabled] = useState(false);
    const [nextBtnEnabled, setNextBtnEnabled] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [scrollSnaps, setScrollSnaps] = useState<number[]>([]);

    const [emblaRef, embla] = useEmblaCarousel({ ...emblaOptions, startIndex: initialSlide });

    useEffect(() => {
        setEmblaOptions((prevOptions) => ({
            ...prevOptions,
            slidesToScroll: slidesPerPage,
            loop,
        }));
    }, [slidesPerPage, loop, initialSlide]);

    const setSlidesSize = useCallback(() => {
        const list = listOfBreakpoints.slice(0, listOfBreakpoints.indexOf(breakpoint)).reverse();

        if (breakpoints) {
            const values = breakpoints[breakpoint] ??
                list
                    .map((key) => breakpoints[key as BreakpointKeys])
                    .filter((x) => x)
                    .firstOrDefault() ?? { slidesPerView, spaceBetween };

            setSlidesPerPage(values.slidesPerView);
            setSpace(values.spaceBetween ?? space);
            setNavigation(values.navigation ?? initNavigation);
        }
    }, [breakpoint, breakpoints]);

    const prevSlide = useCallback(() => {
        if (!embla) return;
        embla.scrollPrev();
        setBackwards(true);
    }, [embla]);
    const nextSlide = useCallback(() => {
        if (!embla) return;
        embla.scrollNext();
        setBackwards(false);
    }, [embla]);
    const scrollTo = useCallback((index) => embla && embla.scrollTo(index), [embla]);
    const clickWrapper = useCallback(
        (item: T) => {
            if (embla?.clickAllowed() && onCarouselItemClick) onCarouselItemClick(item);
        },
        [embla, onCarouselItemClick]
    );

    useEffect(() => {
        setSlidesSize();
    }, [breakpoint]);

    const slides = useMemo(
        () =>
            data?.map((item, idx) => (
                <EmblaSlide key={keyExtractor(item, idx)} onClick={() => clickWrapper(item)}>
                    <Template {...(item as never)} {...extraData} />
                </EmblaSlide>
            )),
        [data, keyExtractor, embla]
    );

    const onSelect = useCallback(() => {
        if (!embla) return;
        const selected = embla.selectedScrollSnap();
        setSelectedIndex(selected);
        setPrevBtnEnabled(embla.canScrollPrev());
        setNextBtnEnabled(embla.canScrollNext());
    }, [embla, setSelectedIndex]);

    const onSettle = useCallback(() => {
        if (!embla || !onSlideChange) return;
        const selected = embla.selectedScrollSnap();
        onSlideChange(selected);
    }, [embla, onSlideChange]);

    useEffect(() => {
        if (!embla) return;
        embla.reInit(emblaOptions);
        onSelect();
        setScrollSnaps(embla.scrollSnapList());
        embla.on('select', onSelect);
        embla.on('settle', () => {
            setDragging(false);
            onSettle();
        });
        embla.on('scroll', () => {
            if (!scrollThrottleTimerId) {
                setDragging(true);
                scrollThrottleTimerId = setTimeout(() => (scrollThrottleTimerId = undefined), 200);
            }
        });

        return () => {
            if (!embla) return;
            onSelect();
            onSettle();
            embla.off('select', onSelect);
            embla.off('settle', onSettle);
        };
    }, [emblaOptions, slides, embla, setScrollSnaps, onSelect, onSettle]);

    useInterval(
        () => {
            if (backwards && embla?.canScrollPrev()) {
                embla?.scrollPrev();
            } else if (backwards && !embla?.canScrollPrev()) {
                setBackwards(false);
                embla?.scrollNext();
            } else if (!backwards && embla?.canScrollNext()) {
                embla?.scrollNext();
            } else if (!backwards && !embla?.canScrollNext()) {
                setBackwards(true);
                embla?.scrollPrev();
            }
        },
        autoplay ? autoplaySpeed : null
    );

    return (
        <EmblaWrapper revealNextSlide={revealNextSlide} scrollbarWidth={scrollbarWidth}>
            <Embla
                ref={emblaRef}
                {...props}
                revealNextSlide={revealNextSlide}
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
            >
                <EmblaContainer slidesPerView={slidesPerPage} spaceBetween={space} revealNextSlide={revealNextSlide}>
                    {slides}
                </EmblaContainer>
                {navigation ? (
                    <>
                        <EmblaButtonPrevious
                            variant={variant}
                            hidden={(!alwaysShowArrowButtons && !hover) || dragging}
                            disabled={!prevBtnEnabled}
                            onClick={prevSlide}
                            aria-label={'previous'}
                        >
                            <SvgIcon svg="chevronLeft" color={theme.colors.white} size="md" />
                        </EmblaButtonPrevious>
                        <EmblaButtonNext
                            variant={variant}
                            hidden={(!alwaysShowArrowButtons && !hover) || dragging}
                            disabled={!nextBtnEnabled}
                            onClick={nextSlide}
                            aria-label={'next'}
                        >
                            <SvgIcon svg="chevronRight" color={theme.colors.white} size="md" />
                        </EmblaButtonNext>
                    </>
                ) : (
                    <></>
                )}
                {pagination && !showPaginationBelow && scrollSnaps.length > 1 ? (
                    <EmblaPagination>
                        {scrollSnaps.map((scrollSnap, idx) => (
                            <EmblaPaginationBullet
                                selected={idx === selectedIndex}
                                key={key({ scrollSnap, idx })}
                                onClick={() => scrollTo(idx)}
                                aria-label={`go to page ${idx}`}
                            />
                        ))}
                    </EmblaPagination>
                ) : (
                    <></>
                )}
            </Embla>
            {pagination && showPaginationBelow && scrollSnaps.length > 1 && (
                <EmblaPaginationBelow>
                    {scrollSnaps.map((scrollSnap, idx) => (
                        <EmblaPaginationBullet
                            selected={idx === selectedIndex}
                            key={key({ scrollSnap, idx })}
                            onClick={() => scrollTo(idx)}
                            aria-label={`go to page ${idx}`}
                        />
                    ))}
                </EmblaPaginationBelow>
            )}
        </EmblaWrapper>
    );
};

const EmblaWrapper = styled.div<Partial<Options>>(({ revealNextSlide, theme, scrollbarWidth }) => ({
    ...(revealNextSlide
        ? merge(
              breakContainer(theme, 'both', scrollbarWidth),
              partialContainer(theme, 'paddingLeft', scrollbarWidth),
              partialContainer(theme, 'paddingRight', scrollbarWidth)
          )
        : {}),
    overflow: 'hidden',
    position: 'relative',
}));

const Embla = styled.div<Pick<SlideOptions, 'revealNextSlide'>>(({ revealNextSlide }) => ({
    position: 'relative',
    overflow: revealNextSlide ? 'visible' : 'initial',

    [mq('frame')]: {
        overflow: 'hidden',
    },
}));

const EmblaContainer = styled.div<Options>(({ slidesPerView, spaceBetween, revealNextSlide }) => ({
    display: 'grid',
    gridAutoFlow: 'column',

    gridAutoColumns: `calc(((100% - (${spaceBetween}px * ${
        slidesPerView > 1 ? slidesPerView - 1 : slidesPerView
    })) / ${slidesPerView}) * ${revealNextSlide ? 0.9 : 1})`,
    columnGap: `${spaceBetween}px`,

    [mq('frame')]: {
        gridAutoColumns: `calc((100% - (${spaceBetween}px * ${
            slidesPerView > 1 ? slidesPerView - 1 : slidesPerView
        })) / ${slidesPerView})`,
    },
}));

const EmblaSlide = styled.div({
    position: 'relative',
});

const EmblaButton = styled.button<{ disabled?: boolean; hidden?: boolean; variant?: Variant }>(
    ({ disabled, hidden, theme }) => ({
        position: 'absolute',
        top: '50%',
        backgroundColor: theme.colors.black,
        borderRadius: '50%',
        height: '45px',
        width: '45px',
        transition: 'opacity 200ms ease',
        borderStyle: 'none',
        opacity: hidden ? 0 : 1,
        display: disabled ? 'none' : 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        overflow: 'visible',
    }),
    switchProp('variant', {
        product: ({ theme }) => ({
            [mq('md')]: {
                top: `calc(((${theme.space[9]} * 2) + 213px) / 2)`,
            },
            top: `calc(213px / 2)`,
        }),
    })
);

const EmblaButtonPrevious = styled(EmblaButton)({
    left: '22px',
    transform: 'translateY(-50%)',
});

const EmblaButtonNext = styled(EmblaButton)({
    right: '22px',
    transform: 'translateY(-50%)',
});

const EmblaPagination = styled(Flex)({
    position: 'absolute',
    left: '50%',
    bottom: '2rem',
    transform: 'translateX(-50%)',
    justifyContent: 'center',
});

const EmblaPaginationBelow = styled(Flex)(({ theme }) => ({
    justifyContent: 'center',
    paddingTop: theme.space[6],
}));

const EmblaPaginationBullet = styled.button<{ selected: boolean }>(({ theme, selected }) => ({
    display: 'block',
    fontSize: 0,
    width: '12px',
    height: '12px',
    border: `1px solid ${theme.colors.black}`,
    borderRadius: '50%',
    margin: '0 4px',
    padding: '0px',
    cursor: 'pointer',
    backgroundColor: selected ? theme.colors.black : 'rgba(0,0,0,0)',
}));
