import React, { FC } from 'react';
import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';
import { switchProp } from 'styled-tools';
import { getColor } from '$lib/get-color';
import { mq } from '$lib/helpers';
import { SpotLayoutProps } from '$components/spots/components/spot-layout';
import { Text } from '$components/elements/text';

export type CircleMiniSpotProps = {
    circleHeading?: string;
    circleSuperText?: string;
    circleTeaserText?: string;
} & CircleProps;

type Props = CircleMiniSpotProps & {
    textColor?: string;
};

export const CircleMiniSpot: FC<Props> = ({
    children,
    circleHeading,
    circleSuperText,
    circleTeaserText,
    textColor,
    ...props
}) => {
    const theme = useTheme();

    return circleHeading || circleSuperText || circleTeaserText ? (
        <Container>
            <Circle {...props}>
                <CircleText color={getColor(theme, textColor)} variant="body" fontWeight="Bold" center>
                    {circleSuperText}
                </CircleText>
                <Text color={getColor(theme, textColor)} variant="circleHeading" fontWeight="Bold" center>
                    {circleHeading}
                </Text>
                <CircleText color={getColor(theme, textColor)} variant="body" center>
                    {circleTeaserText}
                </CircleText>
            </Circle>
            {children}
        </Container>
    ) : (
        <>{children}</>
    );
};

const CircleText = styled(Text)({
    [mq(0, 'md')]: {
        fontSize: 9,
        lineHeight: 2,
        fontWeight: 500,
    },
});

const Container = styled.div({
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    rowGap: '10px',
    maxHeight: '100%',
});

type CircleProps = {
    circleBackgroundColor?: string;
    circleHorizontalPosition?: 'left' | 'center' | 'right';
    circleVerticalPosition?: 'top' | 'middle' | 'bottom';
} & Pick<SpotLayoutProps, 'width'>;

const Circle = styled.div<CircleProps>(
    ({ theme, circleBackgroundColor }) => ({
        position: 'absolute',
        backgroundColor: getColor(theme, circleBackgroundColor),
        width: theme.sizes.circleWidthSmall,
        height: theme.sizes.circleWidthSmall,
        borderRadius: theme.general.circleRadius,
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        zIndex: theme.zIndices[1],
        [mq('md')]: {
            width: theme.sizes.circleWidth,
            height: theme.sizes.circleWidth,
        },
    }),
    switchProp('circleHorizontalPosition', {
        left: ({ theme, width }) => ({
            left: 0,
            [mq('md')]: {
                left: width === 'full' ? `-${theme.sizes.circleWidth}` : `calc((-${theme.sizes.circleWidth} / 10) * 8)`,
            },
            [mq(0, 'md')]: {
                left: `calc((-${theme.sizes.circleWidthSmall} / 10) * 8)`,
            },
        }),
        center: {
            top: '50%',
            transform: 'translateY(-50%)',
        },
        right: ({ theme, width }) => ({
            right: 0,
            [mq('md')]: {
                right:
                    width === 'full' ? `-${theme.sizes.circleWidth}` : `calc((-${theme.sizes.circleWidth} / 10) * 8)`,
            },
            [mq(0, 'md')]: {
                right: `calc((-${theme.sizes.circleWidthSmall} / 10) * 8)`,
            },
        }),
    }),
    switchProp('circleVerticalPosition', {
        top: {
            top: 0,
        },
        bottom: {
            bottom: 0,
        },
    })
);
