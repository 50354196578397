import styled from '@emotion/styled';
import * as ScrollArea from '@radix-ui/react-scroll-area';
import { ifProp } from 'styled-tools';

export const StyledScrollArea = styled(ScrollArea.Root)(() => ({
    overflow: 'hidden',
    cursor: 'grab',

    scrollbarWidth: 'none',
    '::-webkit-scrollbar': {
        display: 'none',
    },

    '&:active': {
        cursor: 'grabbing',
    },
}));

export const StyledItemWrapper = styled(ScrollArea.Viewport, {
    shouldForwardProp: (prop: string) => prop !== 'hasGutter',
})<{ hasGutter: boolean }>(
    {
        overflowX: 'scroll',
    },
    ifProp('hasGutter', ({ theme }) => ({
        paddingLeft: theme.sizes.siteGutter,
        paddingRight: theme.sizes.siteGutter,
    }))
);

export const StyledThumb = styled(ScrollArea.Thumb)(({ theme }) => ({
    flex: 1,
    background: theme.colors.primary,
    borderRadius: 3,
    // increase target size for touch devices https://www.w3.org/WAI/WCAG21/Understanding/target-size.html
    position: 'relative',
    cursor: 'grab',
    '&:active': {
        cursor: 'grabbing',
    },
    '&::before': {
        content: '""',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '100%',
        height: '100%',
        minWidth: 44,
        minHeight: 44,
    },
}));

export const StyledScrollbar = styled(ScrollArea.Scrollbar)(({ theme }) => ({
    display: 'flex',
    margin: 2,
    backgroundColor: theme.colors.grey15,
    // ensures no selection
    userSelect: 'none',
    // disable browser handling of all panning and zooming gestures on touch devices
    touchAction: 'none',
    transition: 'background 160ms ease-out',
    // '&:hover': { background: theme.colors.grey30 },
    '&[data-orientation="vertical"]': { width: 2 },
    '&[data-orientation="horizontal"]': {
        flexDirection: 'column',
        height: 2,
    },
}));

export const StyledScrollBarContainer = styled.div<{ hasGutter: boolean; showScrollbar: boolean }>(
    ({ showScrollbar, theme }) => ({
        position: 'relative',
        padding: `${theme.space[9]} 0 0 0`,
        zIndex: 1,
        visibility: showScrollbar ? 'visible' : 'hidden',
    }),
    ifProp('hasGutter', ({ theme }) => ({
        left: theme.sizes.siteGutter,
        right: theme.sizes.siteGutter,
        width: `calc(100% - ${theme.sizes.siteGutter} - ${theme.sizes.siteGutter})`,
    }))
);
