import React, { FC, useCallback } from 'react';
import styled from '@emotion/styled';
import { Carousel } from '$components/elements/carousel';
import { ProductCard } from '$components/elements/product-card';
import { IBogOgIdeBusinessProductISanitized, IProductTrackingInfoModel } from '$models';
import { key } from '~/utils';
import { trackRaptorRecommendationProductClick } from '$services/gtm.service';
import { SpotLayoutWithHeading } from './components/spot-layout-with-heading';
import { useRaptor } from '$hooks';

type Props = {
    products: IBogOgIdeBusinessProductISanitized[];
    heading: string;
    subHeading: string;
    trackingInfo?: IProductTrackingInfoModel;
    visibilityClass?: string;
    ignoreVisibilityClass?: boolean;
    eagerLoad?: boolean;
};

export const ProductCarouselSpot: FC<Props> = (props: Props) => {
    const { productCarouselClicked } = useRaptor();

    const trackRaptorClick = useCallback(
        (product: IBogOgIdeBusinessProductISanitized) => {
            if (props.trackingInfo?.feedType) {
                productCarouselClicked(product, props.trackingInfo?.feedType);
                trackRaptorRecommendationProductClick(
                    props.trackingInfo?.feedType,
                    props.trackingInfo?.recommendationHeader || '',
                    product
                );
            }
        },
        [productCarouselClicked, props.trackingInfo]
    );

    if (!props.products?.length) {
        return <></>;
    }

    return (
        <ProductCarouselSpotWrapper {...props} heading={props.heading} subHeading={props.subHeading}>
            <Carousel
                slidesPerView={2}
                spaceBetween={10}
                data={props.products}
                extraData={props.eagerLoad}
                keyExtractor={key}
                revealNextSlide
                breakpoints={{
                    md: {
                        slidesPerView: 3,
                        spaceBetween: 10,
                        navigation: true,
                    },
                    frame: {
                        slidesPerView: 4,
                        spaceBetween: 10,
                        navigation: true,
                    },
                    lg: {
                        slidesPerView: 5,
                        spaceBetween: 20,
                        navigation: true,
                    },
                }}
                template={ProductCard}
                variant="product"
                onCarouselItemClick={props.trackingInfo ? (product) => trackRaptorClick(product) : undefined}
                alwaysShowArrowButtons
            />
        </ProductCarouselSpotWrapper>
    );
};

const ProductCarouselSpotWrapper = styled(SpotLayoutWithHeading)(({ theme }) => ({
    marginBottom: theme.space[10],
    minWidth: 0, // Firefox grid fix
}));
