import React, { FC, useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { motion, useAnimation } from 'framer-motion';
import { switchProp, ifProp } from 'styled-tools';
import { mq } from '$lib/helpers';
import { useIntersectionObserver } from '$hooks';

export type SpotLayoutProps = {
    backgroundColor?: string;
    height?: 'short' | 'average' | 'tall' | 'auto' | undefined;
    width?: 'small' | 'medium' | 'large' | 'full' | undefined;
    visibilityClass?: string;
    ignoreVisibilityClass?: boolean;
    mobileHeight?: string;
    eagerLoad?: boolean;
};

type Props = SpotLayoutProps;

export const SpotLayout: FC<Props> = ({ eagerLoad, ...props }) => {
    const [hasIntersected, setHasIntersected] = useState(false);
    const spotRef = useRef<HTMLElement>(null);
    const intersection = useIntersectionObserver(spotRef, {
        threshold: 0.25,
    });
    const controls = useAnimation();

    useEffect(() => {
        if (intersection?.isIntersecting && !hasIntersected) {
            setHasIntersected(true);
            controls.start('visible');
        }
    }, [intersection?.isIntersecting]);

    return (
        <SpotLayoutSection
            {...props}
            ref={spotRef}
            animate={controls}
            initial={eagerLoad ? 'visible' : 'hidden'}
            variants={{ visible: { opacity: 1, translateY: '0px' }, hidden: { opacity: 0, translateY: '20px' } }}
            hasIntersected={eagerLoad ? true : hasIntersected}
        />
    );
};

const SpotLayoutSection = styled(motion.section)<Props & { hasIntersected: boolean }>(
    ({ backgroundColor, visibilityClass, ignoreVisibilityClass, theme }) => ({
        backgroundColor: backgroundColor,
        gridColumn: 'span 12',
        minWidth: 0, // Firefox grid fix

        ':last-child': {
            marginBottom: theme.space[7],
        },
        [mq(0, 'md')]: {
            ...(!ignoreVisibilityClass && !visibilityClass?.includes('phone') ? { display: 'none' } : {}),
        },
        [mq('md', 'lg')]: {
            ...(!ignoreVisibilityClass && !visibilityClass?.includes('tablet') ? { display: 'none' } : {}),
        },
        [mq('lg')]: {
            ...(!ignoreVisibilityClass && !visibilityClass?.includes('desktop') ? { display: 'none' } : {}),
        },
    }),
    switchProp('height', {
        short: ({ theme }) => ({
            gridRow: 'span 1',
            height: theme.spots.spotHeightShort,
        }),
        average: ({ theme }) => ({
            gridRow: 'span 2',
            height: theme.spots.spotHeightAverage,
        }),
        tall: ({ theme }) => ({
            gridRow: 'span 3',
            height: theme.spots.spotHeightTall,
        }),
    }),
    switchProp('width', {
        small: {
            [mq('md')]: {
                gridColumn: 'span 3',
            },
        },
        medium: {
            [mq('md')]: {
                gridColumn: 'span 4',
            },
        },
        large: {
            [mq('md')]: {
                gridColumn: 'span 6',
            },
        },
    }),
    ifProp('mobileHeight', ({ mobileHeight }) => ({
        [mq(0, 'md')]: {
            height: mobileHeight,
        },
    })),
    ifProp(
        { hasIntersected: false },
        {
            ' .lazy-bg-img': {
                backgroundImage: 'none',
            },
        }
    )
);
