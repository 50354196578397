import React, { FC, useCallback } from 'react';
import styled from '@emotion/styled';
import { flexColumn } from '$lib/style-helpers';
import { Image } from '$components/elements/image';
import { Link } from '$components/elements/link';
import { mq } from '$lib/helpers';
import { ImageTextSpotModel } from '$models/spots-types';
import {
    FrameMarker,
    ImageTextButton,
    ImageTextButtonIcon,
    ImageTextButtonText,
    ImageTextContentFlexWrapper,
    ImageTextContentTextWrapper,
    ImageTextSpotHeader,
    ImageTextSpotText,
} from '../shared/styles';
import { Tags } from '../shared/tags';

type ImageTextSpotType = 'Hero' | 'Content';

type Props = {
    hasFixedTagHeight?: boolean;
    imageTopLeftContent?: React.ReactNode;
} & ImageTextSpotModel;

export const StorySpot: FC<Props> = (props) => {
    const rightPositionImage = props.imagePlacement === 'Right';

    const renderMarkings = useCallback(() => {
        const withTagLine = false;
        if (!props.frameMarkings?.color) {
            return null;
        }
        return (
            <>
                <FrameMarker
                    svg="frame"
                    size={28}
                    spotStyle={props.style}
                    color={props.frameMarkings.color}
                    position="topleft"
                    rotate={-90}
                />
                <FrameMarker
                    svg="frame"
                    size={28}
                    spotStyle={props.style}
                    color={props.frameMarkings.color}
                    position="topright"
                />
                {withTagLine ? (
                    <FrameMarker
                        svg="frameWithTagline"
                        width={145}
                        height={28}
                        spotStyle={props.style}
                        color={props.frameMarkings.color}
                        position="bottomright"
                    />
                ) : (
                    <FrameMarker
                        svg="frame"
                        size={28}
                        spotStyle={props.style}
                        color={props.frameMarkings.color}
                        position="bottomright"
                        rotate={90}
                    />
                )}
            </>
        );
    }, [props.frameMarkings, props.style]);

    return (
        <>
            <ImageContainerStorySpot spotType={props.type} rightPositionImage={rightPositionImage}>
                {!!props.image?.url && (
                    <Link href={props.link?.url} target={props.link?.target} aria-label={props.callToAction}>
                        <Image
                            alt={props.image.altText}
                            src={props.image?.url}
                            priority={props.type === 'Hero'}
                            objectFit="cover"
                            objectPosition="center"
                            layout="fill"
                        />
                    </Link>
                )}
                {props.imageTopLeftContent && (
                    <ImageTopLeftContentWrapper>{props.imageTopLeftContent}</ImageTopLeftContentWrapper>
                )}
                {props.style === 'Story' && renderMarkings()}
            </ImageContainerStorySpot>
            <ContentContainerStorySpot spotType={props.type} leftPositionContent={rightPositionImage}>
                <ImageTextContentTextWrapper maxWidth={560}>
                    <ImageTextContentFlexWrapper>
                        <Tags
                            tags={props.tags}
                            color={props.theme?.textColor}
                            hoverTextColor={props.theme?.buttonTextColor}
                            withFixedHeight={props.hasFixedTagHeight}
                        />
                        {!!props.header && (
                            <ImageTextSpotHeader
                                as={props.type === 'Hero' ? 'h1' : 'h2'}
                                spotStyle={props.style}
                                spotType={props.type}
                            >
                                <Link
                                    href={props.link?.url}
                                    target={props.link?.target}
                                    aria-label={props.callToAction}
                                    applyTextStyling={false}
                                >
                                    {props.header}
                                </Link>
                            </ImageTextSpotHeader>
                        )}
                        <ImageTextSpotText>{props.description}</ImageTextSpotText>
                    </ImageTextContentFlexWrapper>
                    {(!!props?.link?.url || !!props?.callToAction) && (
                        <ImageTextButton
                            spotTheme={props.theme}
                            as={Link}
                            applyTextStyling={false}
                            variant="primary"
                            href={props.link?.url}
                            target={props.link?.target}
                            aria-label={props.callToAction}
                        >
                            <ImageTextButtonText>{props.callToAction}</ImageTextButtonText>
                            <ImageTextButtonIcon
                                size={16}
                                color={props.theme?.buttonTextColor}
                                aria-hidden="true"
                                rotate={180}
                                svg="arrowLeft"
                            />
                        </ImageTextButton>
                    )}
                </ImageTextContentTextWrapper>
            </ContentContainerStorySpot>
        </>
    );
};

const breakPoint = 'frame';

export const ImageContainerStorySpot = styled.div<{ spotType: ImageTextSpotType; rightPositionImage: boolean }>(
    ({ spotType, rightPositionImage }) => ({
        position: 'relative',
        aspectRatio: '3 / 2',
        width: '100%',

        [mq(breakPoint)]: {
            width: spotType === 'Hero' ? '66%' : '58%',
            height: '100%',
            ...(rightPositionImage && {
                order: 2,
            }),
        },
        [mq('lg')]: {
            width: spotType === 'Hero' ? '72%' : '58%',
        },
    })
);

export const ImageTopLeftContentWrapper = styled.div(() => ({
    position: 'absolute',
    top: 20,
    left: 20,
}));

export const ContentContainerStorySpot = styled.div<{
    spotType: ImageTextSpotType;
    leftPositionContent: boolean;
}>(({ spotType, leftPositionContent }) => ({
    ...flexColumn,
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
    padding: 32,
    paddingBottom: 40,
    paddingTop: 32,

    [mq(breakPoint)]: {
        width: spotType === 'Hero' ? '28%' : '42%',
        height: '100%',
        flex: 1,
        padding: 48,
        paddingLeft: spotType === 'Content' ? 48 : undefined,
        paddingRight: spotType === 'Content' ? 100 : 60,
        paddingBottom: spotType === 'Content' ? 48 : 60,

        ...(leftPositionContent && {
            alignItems: 'flex-end',
            paddingRight: spotType === 'Content' ? 48 : undefined,
            paddingLeft: spotType === 'Content' ? 100 : 60,
            [`& ${ImageTextSpotHeader}`]: {
                textAlign: 'right',
            },
            [`& ${ImageTextContentTextWrapper}`]: {
                alignItems: 'flex-end',
            },
            [`& ${ImageTextContentFlexWrapper}`]: {
                alignItems: 'flex-end',
            },
            [`& ${ImageTextSpotText}`]: {
                textAlign: 'right',
            },
        }),
    },
    [mq('lg')]: {
        paddingRight: 100,
    },
}));
