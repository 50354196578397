import React, { FC } from 'react';
import styled from '@emotion/styled';
import { ifNotProp, switchProp } from 'styled-tools';
import { Flex } from '$components/layouts';
import { SpotLayout, SpotLayoutProps } from '../../components/spot-layout';
import { IFieldTypesModelLink, IImageResource } from '$models';
import { getColor } from '$lib/get-color';
import { ThemeType } from '$theme';
import { getUrlWithHeightWidth, imageUrl, mq } from '$lib/helpers';
import { OptionalLink, OptionalLinkProps } from '$components/elements/link';

export type CampaignSpotLayoutProps = {
    link?: IFieldTypesModelLink;
} & SpotLayoutProps &
    Omit<CampaignSpotBackgroundProps, 'hideBackgroundImage'>;

type Props = CampaignSpotLayoutProps & CampaignSpotBackgroundProps & OptionalLinkProps & SpotContainerProps;

export const CampaignSpotLayout: FC<Props> = ({ children, hideLink, contentMargin, ...props }) => {
    return (
        <SpotLayout {...props}>
            <OptionalLink hideLink={hideLink || !props.link} href={props.link?.url} target={props.link?.target}>
                <CampaignSpotBackground {...props} className="lazy-bg-img">
                    <SpotContent
                        column
                        alignItems="center"
                        justifyContent="center"
                        contentMargin={contentMargin}
                        {...props}
                    >
                        {children}
                    </SpotContent>
                </CampaignSpotBackground>
            </OptionalLink>
        </SpotLayout>
    );
};

type SpotContainerProps = { contentMargin?: boolean };

const SpotContent = styled(Flex)<SpotContainerProps>(
    switchProp('width', {
        small: ({ theme: { space } }) => ({
            paddingLeft: space[8],
            paddingRight: space[8],
            [mq('md')]: {
                paddingLeft: space[6],
                paddingRight: space[6],
            },
            [mq('ml')]: {
                paddingLeft: space[6],
                paddingRight: space[6],
            },
        }),
        medium: ({ theme: { space } }) => ({
            paddingLeft: space[8],
            paddingRight: space[8],
            [mq('sm')]: {
                paddingLeft: space[10],
                paddingRight: space[10],
            },
            [mq('md')]: {
                paddingLeft: space[4],
                paddingRight: space[4],
            },
            [mq('ml')]: {
                paddingLeft: space[6],
                paddingRight: space[6],
            },
        }),
        large: ({ theme: { space } }) => ({
            paddingLeft: space[9],
            paddingRight: space[9],
            [mq(600)]: {
                paddingLeft: space[12],
                paddingRight: space[12],
            },
            [mq('md')]: {
                paddingLeft: space[6],
                paddingRight: space[6],
            },
            [mq('ml')]: {
                paddingLeft: space[10],
                paddingRight: space[10],
            },
        }),
        default: ({ theme: { space } }) => ({
            paddingLeft: space[8],
            paddingRight: space[8],
            [mq('md')]: {
                paddingLeft: space[10],
                paddingRight: space[10],
            },
            [mq('ml')]: {
                paddingLeft: space[12],
                paddingRight: space[12],
            },
        }),
    }),
    ifNotProp('contentMargin', ({ theme }) => ({ padding: theme.spots.spotContentPadding }))
);

const getHeight = (height: SpotLayoutProps['height'], theme: ThemeType) => {
    switch (height) {
        case 'auto':
            return undefined;
        case 'average':
            return parseInt(theme.spots.spotHeightAverage) * parseInt(theme.fontSizes.default);
        case 'short':
            return parseInt(theme.spots.spotHeightShort) * parseInt(theme.fontSizes.default);
        case 'tall':
            return parseInt(theme.spots.spotHeightTall) * parseInt(theme.fontSizes.default);
    }
};

const getBackgroundImageUrl = (options: { url?: string; theme: ThemeType } & Pick<SpotLayoutProps, 'height'>) => {
    return getUrlWithHeightWidth(
        imageUrl(options?.url ?? ''),
        'max',
        undefined,
        undefined,
        getHeight(options.height, options.theme)
    );
};

export type CampaignSpotBackgroundProps = {
    horizontalPosition?: 'center' | 'left' | 'right';
    verticalPosition?: 'middle' | 'top' | 'bottom';
    allowImageToBeCropped?: boolean;
    imageViewModel?: IImageResource;
    backgroundColor?: string;
    hideBackgroundImage?: boolean;
} & Pick<SpotLayoutProps, 'height'>;

export const CampaignSpotBackground = styled.div<CampaignSpotBackgroundProps>(
    ({
        allowImageToBeCropped,
        imageViewModel,
        horizontalPosition,
        verticalPosition,
        backgroundColor,
        hideBackgroundImage,
        height,
        theme,
    }) => ({
        display: 'flex',
        alignContent: 'center',
        justifyContent: 'center',
        backgroundColor: getColor(theme, backgroundColor),
        height: '100%',
        backgroundSize: allowImageToBeCropped ? 'cover' : 'contain',
        backgroundImage:
            hideBackgroundImage || !imageViewModel?.url
                ? undefined
                : `url("${getBackgroundImageUrl({ url: imageViewModel?.url, height, theme })}")`,
        backgroundPositionX: horizontalPosition,
        backgroundPositionY: verticalPosition === 'middle' ? 'center' : verticalPosition,
        backgroundRepeat: 'no-repeat',
    })
);
