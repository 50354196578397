import { BOISvg } from '$components/elements/svg-icon';

export const getSocialIcon = (iconClass?: string): keyof typeof BOISvg => {
    switch (iconClass) {
        case '--facebook':
            return 'btnSocialFacebook';
        case '--instagram':
            return 'btnSocialInstagram';
        case '--newsletter':
        case '--contact':
            return 'btnSocialNewsletter';
        case '--pinterest':
            return 'btnSocialPinterest';
        case '--club':
            return 'btnSocialClub';
        default:
            return 'fail';
    }
};
