import React, { FC, useCallback } from 'react';
import styled from '@emotion/styled';
import { hexToRGB, mq } from '$lib/helpers';
import { ImageTextSpotTag } from '$models/spots-types';
import { Link } from '$components/elements/link';

type Props = {
    tags?: ImageTextSpotTag[];
    color?: string;
    hoverTextColor?: string;
    withFixedHeight?: boolean;
};

export const Tags: FC<Props> = ({ tags, color, hoverTextColor, withFixedHeight }) => {
    const renderTags = useCallback(() => {
        // Tags was a string array before, so this is to prevent SPA crash when BE changes data
        if (!tags || typeof tags === 'string') {
            return <></>;
        }
        return (
            <>
                {tags?.map((tag, index) => (
                    <TagLink key={`${tag?.header}${index}`} href={tag.link?.url} target={tag.link?.target}>
                        <TagItem key={index} color={color} hoverTextColor={hoverTextColor}>
                            {tag.header}
                        </TagItem>
                    </TagLink>
                ))}
            </>
        );
    }, [tags, color]);

    return (
        <TagContainer fixedHeight={withFixedHeight} hasTags={!!tags}>
            {renderTags()}
        </TagContainer>
    );
};

export const TagContainer = styled.div<{ fixedHeight?: boolean; hasTags?: boolean }>(({ fixedHeight, hasTags }) => ({
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: 6,
    ...(!hasTags && {
        display: 'none',
    }),
    [mq('md')]: {
        ...(fixedHeight
            ? {
                  minHeight: 21,
                  display: 'flex',
              }
            : {
                  ...(!hasTags && {
                      display: 'none',
                  }),
              }),
    },

    [mq('lg')]: {
        marginBottom: 14,
    },
}));

export const TagLink = styled(Link)({
    marginRight: 8,
    marginBottom: 10,

    ':last-child': {
        marginRight: 0,
    },
});

export const TagItem = styled.div<{ color?: string; hoverTextColor?: string }>(({ color, hoverTextColor, theme }) => ({
    ...(color && {
        border: `1px solid ${hexToRGB(color, 0.8)}`,
        color: color,
    }),
    fontSize: theme.fontSizes.xxs,
    fontWeight: theme.fontWeights.medium,
    textTransform: 'uppercase',
    padding: '3px 12px',
    borderRadius: 30,
    lineHeight: '17px',

    ':hover': {
        backgroundColor: color,
        color: hoverTextColor,
    },
}));
