import React, { ComponentProps, FC } from 'react';
import styled from '@emotion/styled';
import { switchProp } from 'styled-tools';
import { Text } from '$components/elements/text';
import { mq } from '$lib/helpers';
import { SpotLayoutProps } from './spot-layout';

type SpotHeadingProps = {
    topSpot?: boolean;
};

type Props = SpotHeadingProps & ComponentProps<typeof TextSpotHeadingText> & Pick<SpotLayoutProps, 'width'>;

export const TextSpotHeading: FC<Props> = ({ as, topSpot, ...props }) => {
    return <TextSpotHeadingText {...props} as={topSpot ? 'h1' : as !== 'h1' ? as ?? 'h2' : 'h2'} width={props.width} />;
};

const TextSpotHeadingText = styled(Text)<{ width: 'small' | 'medium' | 'large' | 'full' | undefined }>(
    ({ theme }) => ({ marginBottom: theme.space[2] }),
    switchProp('width', {
        small: ({ theme }) => ({
            ...theme.mixins.useTextStyle('display3'),
            [mq('md')]: {
                ...theme.mixins.useTextStyle('display5'),
                fontSize: theme.fontSizes.md,
                lineHeight: theme.lineHeights.base,
            },
        }),
        medium: ({ theme }) => ({
            ...theme.mixins.useTextStyle('display2'),
            [mq('md')]: {
                ...theme.mixins.useTextStyle('display5'),
            },
        }),
        large: ({ theme }) => ({
            ...theme.mixins.useTextStyle('display4'),
            [mq('sm')]: {
                ...theme.mixins.useTextStyle('display3'),
            },
            [mq('md')]: {
                ...theme.mixins.useTextStyle('display4'),
            },
            [mq('lg')]: {
                ...theme.mixins.useTextStyle('display2'),
            },
        }),
        default: ({ theme }) => ({
            ...theme.mixins.useTextStyle('display5'),
            [mq('md')]: {
                ...theme.mixins.useTextStyle('display4'),
            },
            [mq('lg')]: {
                ...theme.mixins.useTextStyle('display2'),
            },
        }),
    })
);
