import React, { memo, useEffect, useRef, useState, RefObject, MouseEvent, MutableRefObject } from 'react';
import { useReducedMotion } from 'framer-motion';
import { useIntersection, useInterval, useEffectOnce } from 'react-use';
import { StyledItemWrapper, StyledScrollArea, StyledScrollBarContainer, StyledScrollbar, StyledThumb } from './styled';
import { elasticScroll, getAverageVelocity } from './utils';
import { defaultConfig, AdjustableConfig } from './config';
import { breakpoints } from '$theme';

export type CarouselDragProps = {
    children?: JSX.Element[] | JSX.Element;
    /**
     * Automatic scrolling through the carousel, when it is in view.
     */
    autoPlay?: boolean;
    /**
     * Ref to a button element. This element will scroll left on click.
     */
    leftArrowRef?: RefObject<HTMLButtonElement> | null;
    /**
     * Ref to a button element. This element will scroll right on click.
     */
    rightArrowRef?: RefObject<HTMLButtonElement> | null;
    /**
     * @property {number}   momentumMinSpeed  The minimum speed in momentum scroll for it to stop completely.
     * @property {number}   momentumDeclineRate  How fast momentum will decline
     * @property {number}   boundaryResistance  The extra resistance when dragging out of bounds
     * @property {number}   boundarySnapBackDuration  Transition time between scrolling out of bounds and snapping back to normal
     * @property {number}   arrowScrollLength  Describes how far there will be scrolled on left/right arrow click
     * @property {boolean}   scrollbar  Enable or disable scrollbar
     */
    config?: AdjustableConfig;
    /**
     * Optional timestamp property for stopping internal functions, used when it's not possible
     * to wait until functionality reach it's end execution
     *
     * Pass external event timestamp
     */
    timestamp?: number;
    /**
     * function to be run every time the scroll updates. Usefull to update buttons
     * that should became disabled on scroll end/start
     */
    onUpdateScroll?: (scrollRef: React.RefObject<HTMLDivElement>) => void;
    /**
     * true if the amount of scroll needs to be calculated instead of being full width.
     */
    customScroll?: boolean;
    hasGutter?: boolean;
};

let stopMomentum = false;

// Store values in between start of drag / drag / drag end
let initialScrollPosition: number;
let initialX: number;
let positions: Position[] = [];

type Position = {
    timeStamp: number;
    xPosition: number;
};

export const cardsWidthOptions = {
    xs: 2.75,
    sm: 3.75,
    md: 4.75,
};

/**
 * Wraps and array of children in a draggable carousel.
 * @param children An array of JSX elements.
 * @param leftArrowRef A ref to the element, that will scroll backwards on the x-axis on click.
 * @param rightArrowRef A ref to the element, that will scroll forwards on the x-axis on click.
 * @param autoPlay If set to true, will enable the carousel to scroll by itself when in the viewport.
 * @param stopMoving By changing this value, the carousel will stop its current momentum, og stop autoplay.
 * This can be used, when you want to scroll the carousel from an outer component.
 */

let stateTimeout: NodeJS.Timeout | null = null;
const DEBOUNCE_TIME = 200;

export const CarouselDrag = memo(
    ({
        children = [],
        leftArrowRef,
        rightArrowRef,
        autoPlay = false,
        config,
        timestamp = 0,
        customScroll,
        hasGutter = false,
    }: CarouselDragProps) => {
        const [isDragging, setIsDragging] = useState(false);
        const [autoPlayActive, setAutoPlayActive] = useState(autoPlay);

        const {
            momentumMinSpeed,
            momentumDeclineRate,
            momentumDataSize,

            boundaryResistance,
            boundarySnapBackDuration,

            autoPlayScrollSpeed,
            autoPlayScrollLenght,

            arrowScrollLength,
        } = { ...defaultConfig, ...config };

        // These default values, prevents null checks in the functions.
        // Used to increase readability and performance.
        const slider = useRef<HTMLDivElement>(null);
        const itemWrapper = useRef<HTMLDivElement>(null);

        const shouldReduceMotion = useReducedMotion();

        const intersection = useIntersection(itemWrapper, {});

        const leftArrowClick = () => arrowClick(-1);
        const rightArrowClick = () => arrowClick(1);

        const arrowClick = (direction: number): void => {
            stopMomentum = true;
            setAutoPlayActive(false);

            if (itemWrapper.current !== null) {
                let scrollWidth = itemWrapper.current.getBoundingClientRect().width * arrowScrollLength;
                if (customScroll) {
                    const clientWidth = window.innerWidth || 0;
                    let cardsWidth = cardsWidthOptions.xs;
                    let cardsToScroll = 2;
                    if (clientWidth >= breakpoints.md) {
                        cardsWidth = cardsWidthOptions.md;
                        cardsToScroll = 3;
                    } else if (clientWidth >= breakpoints.sm) {
                        cardsWidth = cardsWidthOptions.sm;
                    }

                    scrollWidth = (itemWrapper.current.getBoundingClientRect().width / cardsWidth) * cardsToScroll + 8; //8px to compensate first child not having left padding
                }

                itemWrapper.current.scrollTo({
                    left: itemWrapper.current.scrollLeft + scrollWidth * direction,
                    behavior: shouldReduceMotion ? 'auto' : 'smooth',
                });
            }
        };

        useInterval(
            () => {
                requestAnimationFrame(() => {
                    if (itemWrapper.current !== null) {
                        itemWrapper.current.scrollLeft += autoPlayScrollLenght;
                    }
                });
            },
            autoPlayActive && intersection?.isIntersecting && !shouldReduceMotion ? autoPlayScrollSpeed : null
        );

        useEffectOnce(() => {
            rightArrowRef?.current?.addEventListener('click', rightArrowClick, { passive: true });
            leftArrowRef?.current?.addEventListener('click', leftArrowClick, { passive: true });
            detectBounds();

            return () => {
                leftArrowRef?.current?.removeEventListener('click', leftArrowClick);
                rightArrowRef?.current?.removeEventListener('click', rightArrowClick);
            };
        });

        useEffect(() => {
            if (timestamp > 0) {
                stopMomentum = true;
                setAutoPlayActive(false);
            }
        }, [timestamp]);

        const detectBounds = () => {
            if (!leftArrowRef?.current || !rightArrowRef?.current) {
                return;
            }

            if (itemWrapper.current !== null) {
                const startReached = itemWrapper.current.scrollLeft <= 0;
                const endReached =
                    itemWrapper.current.scrollLeft + itemWrapper.current.clientWidth >= itemWrapper.current.scrollWidth;

                leftArrowRef.current.disabled = startReached;
                rightArrowRef.current.disabled = endReached;
            }
        };

        const handleDragStart = (event: MouseEvent): void => {
            setIsDragging(true);
            setAutoPlayActive(false);

            if (slider.current !== null && itemWrapper.current !== null) {
                initialScrollPosition = itemWrapper.current.scrollLeft;
                initialX = event.pageX - itemWrapper.current.offsetLeft;

                itemWrapper.current.style.transitionDuration = '0ms';
                positions = [];
            }

            stopMomentum = true;
        };

        const handleDrag = (event: MouseEvent): void => {
            event.preventDefault();

            if (itemWrapper !== null && itemWrapper.current !== null) {
                const desiredScrollLeft =
                    initialScrollPosition + initialX - event.pageX - itemWrapper.current.offsetLeft;

                const outOfBounds = elasticScroll(
                    desiredScrollLeft,
                    itemWrapper as MutableRefObject<HTMLDivElement>,
                    itemWrapper as MutableRefObject<HTMLDivElement>,
                    boundaryResistance
                );

                if (outOfBounds) {
                    return;
                }

                itemWrapper.current.scrollLeft = desiredScrollLeft;

                // Save data, to use later for momentum scroll
                positions.push({
                    timeStamp: event.timeStamp,
                    xPosition: event.pageX,
                });
                if (positions.length > momentumDataSize) {
                    positions.shift();
                }
            }
        };

        const handleDragStop = () => {
            if (itemWrapper.current !== null) {
                setIsDragging(false);

                itemWrapper.current.style.transitionDuration = `${boundarySnapBackDuration}ms`;
                itemWrapper.current.style.transform = '';

                stopMomentum = false;

                if (positions.length >= momentumDataSize) {
                    requestAnimationFrame(() => momentumScroll(getAverageVelocity(positions)));
                }
            }
        };

        // Start scrolling
        const momentumScroll = (velocity: number) => {
            if (itemWrapper.current !== null) {
                if (Math.abs(velocity) < momentumMinSpeed || stopMomentum) {
                    return;
                }

                itemWrapper.current.scrollLeft -= velocity;
                requestAnimationFrame(() => momentumScroll(velocity / momentumDeclineRate));
            }
        };

        const onclickHandler = (event: MouseEvent<HTMLElement>) => {
            if (positions.length > 0) {
                event.preventDefault();
            }
        };

        const scrollHandler = () => {
            if (stateTimeout !== null) {
                clearTimeout(stateTimeout);
            }

            stateTimeout = setTimeout(() => {
                if (leftArrowRef?.current || rightArrowRef?.current) {
                    detectBounds();
                }
            }, DEBOUNCE_TIME);
        };

        return (
            <StyledScrollArea
                type="auto"
                ref={slider}
                onClickCapture={onclickHandler}
                onMouseDown={handleDragStart}
                onMouseLeave={isDragging ? handleDragStop : undefined}
                onMouseUp={isDragging ? handleDragStop : undefined}
                onMouseMove={isDragging ? (event) => handleDrag(event) : undefined}
                onFocus={autoPlayActive ? () => setAutoPlayActive(false) : undefined}
                onTouchStart={autoPlayActive ? () => setAutoPlayActive(false) : undefined}
            >
                <StyledItemWrapper onScroll={scrollHandler} hasGutter={hasGutter} ref={itemWrapper}>
                    {children}
                </StyledItemWrapper>
                <StyledScrollBarContainer hasGutter={hasGutter} showScrollbar={true}>
                    <StyledScrollbar orientation="horizontal">
                        <StyledThumb />
                    </StyledScrollbar>
                </StyledScrollBarContainer>
            </StyledScrollArea>
        );
    }
);
