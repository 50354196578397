import React, { FC } from 'react';
import { IFieldTypesModelLink, IImageResource } from '$models';
import { CampaignSpotLayout, CampaignSpotLayoutProps } from './components/campaign-spot-layout';
import { CircleMiniSpotProps } from './components/circle-mini-spot';
import { OptionalLinkProps } from '$components/elements/link';

export type FeatureSpotBaseProps = {
    backgroundImage?: IImageResource;
    buttonLink?: IFieldTypesModelLink;
    buttonText?: string;
    buttonType?: string;
    contentPosition?: 'left' | 'center' | 'right';
    heading?: string;
    hearts?: number;
    heartsColor?: string;
    linkText?: string;
    spotLink?: IFieldTypesModelLink;
    spotText?: string;
    superText?: string;
    teaser?: string;
    textColor?: string;
} & Omit<CampaignSpotLayoutProps, 'link' | 'imageViewModel'> &
    CircleMiniSpotProps &
    OptionalLinkProps;

type Props = FeatureSpotBaseProps & { contentMargin?: boolean };

export const FeatureSpotBase: FC<Props> = ({ children, ...props }) => {
    return (
        <CampaignSpotLayout {...props} imageViewModel={props.backgroundImage} link={props.spotLink}>
            {children}
        </CampaignSpotLayout>
    );
};
