import React, { FC } from 'react';
import { SpotLayout } from './components/spot-layout';
import { IMAGE_URL } from '$lib/helpers';
import { YoutubeVideo } from '$components/elements/youtube';

type Props = {
    height?: 'short' | 'average' | 'tall' | 'auto' | undefined;
    width?: 'small' | 'medium' | 'large' | 'full' | undefined;
    youtubeId: string;
    thumbnail?: { altText: string; url: string; relativeHeight: string };
};

export const YoutubeSpot: FC<Props> = (props: Props) => {
    const { width, youtubeId, thumbnail } = props;

    return (
        <SpotLayout {...props} height="auto" width={width}>
            {!!youtubeId && (
                <YoutubeVideo youtubeId={youtubeId} thumbnailUrl={thumbnail?.url && `${IMAGE_URL}${thumbnail?.url}`} />
            )}
        </SpotLayout>
    );
};
