import React, { FC } from 'react';
import styled from '@emotion/styled';
import Head from 'next/head';
import { useTheme } from '@emotion/react';
import { switchProp } from 'styled-tools';
import { Text } from '$components/elements/text';
import { key } from '~/utils';
import { SpotLayout, SpotLayoutProps } from '../components/spot-layout';
import { FeatureSpotBaseProps } from '$components/spots/campaign-spots/feature-spot-base';
import { CampaignSpotBackground } from '$components/spots/campaign-spots/components/campaign-spot-layout';
import { Flex } from '$components/layouts';
import { getButtonVariant } from '$lib/get-button';
import { getColor } from '$lib/get-color';
import { getBackgroundImageUrlForPreload, mq } from '$lib/helpers';
import { SpotHeading } from '../components/spot-heading';
import { IContentSpot } from '$models';
import { useButtonOverrides } from '$hooks';
import { theme } from '$theme';
import { Carousel } from '$components/elements/carousel';
import { Button } from '$components/elements/button';
import { Link, OptionalLink } from '$components/elements/link';

export type SliderSpotProps = {
    slides: SlideProps[];
};

type Props = SliderSpotProps & Pick<SpotLayoutProps, 'height' | 'width'> & IContentSpot;

export const SliderSpot: FC<Props> = (props: Props) => {
    const { spots } = useTheme();

    return (
        <>
            {props.eagerLoad && (
                <Head>
                    {props.slides.map((slide, index) => (
                        <link
                            key={`${props.spotName}-${index}`}
                            rel="preload"
                            as="image"
                            href={getBackgroundImageUrlForPreload({
                                url: slide.backgroundImage?.url,
                                height: props.height,
                                theme,
                            })}
                        />
                    ))}
                </Head>
            )}
            <SpotLayout
                height={props.height}
                width={props.width}
                mobileHeight={spots.spotSliderMobileHeight}
                ignoreVisibilityClass
                eagerLoad={props.eagerLoad}
            >
                <Carousel
                    slidesPerView={1}
                    spaceBetween={0}
                    autoplay
                    autoplaySpeed={6000}
                    data={props.slides}
                    pagination
                    navigation
                    keyExtractor={key}
                    template={slideTemplate({ height: props.height, width: props.width })}
                />
            </SpotLayout>
        </>
    );
};

export type SlideProps = FeatureSpotBaseProps;

const slideTemplate =
    ({ height, width }: SlideContainerProps & Pick<SpotLayoutProps, 'width'>): FC<SlideProps> =>
    (props) => {
        const theme = useTheme();
        const { overrides } = useButtonOverrides(props.spotLink?.url);

        return (
            <SlideContainer height={height} {...props}>
                <OptionalLink
                    hideLink={!!props.buttonText || !props.spotLink}
                    href={props.spotLink?.url}
                    target={props.spotLink?.target}
                >
                    <CampaignSpotBackground {...props} imageViewModel={props.backgroundImage} className="lazy-bg-img">
                        <SlideContentContainer column justifyContent="center">
                            <SlideContent
                                contentPosition={width === 'full' ? props.contentPosition ?? 'center' : 'center'}
                                column
                                justifyContent="center"
                                alignItems="center"
                            >
                                {props.heading && (
                                    <SpotHeading variant="display1" color={getColor(theme, props.textColor)} center>
                                        {props.heading}
                                    </SpotHeading>
                                )}
                                {props.spotText && (
                                    <Text color={getColor(theme, props.textColor)} center variant="smallHeader" as="p">
                                        {props.spotText}
                                    </Text>
                                )}
                                {props.buttonText && (
                                    <Button
                                        as={Link}
                                        applyTextStyling={false}
                                        variant={getButtonVariant(props.buttonType)}
                                        href={props.spotLink?.url}
                                        target={props.spotLink?.target}
                                        {...overrides}
                                    >
                                        {props.buttonText}
                                    </Button>
                                )}
                            </SlideContent>
                        </SlideContentContainer>
                    </CampaignSpotBackground>
                </OptionalLink>
            </SlideContainer>
        );
    };

type SlideContainerProps = Pick<SpotLayoutProps, 'height'>;

const SlideContainer = styled.div<SlideContainerProps>(
    ({ theme }) => ({
        [mq(0, 'md')]: {
            height: theme.spots.spotSliderMobileHeight,
        },
    }),
    switchProp('height', {
        short: ({ theme }) => ({
            [mq('md')]: {
                height: theme.spots.spotHeightShort,
            },
        }),
        average: ({ theme }) => ({
            [mq('md')]: {
                height: theme.spots.spotHeightAverage,
            },
        }),
        tall: ({ theme }) => ({
            [mq('md')]: {
                height: theme.spots.spotHeightTall,
            },
        }),
    })
);

const SlideContentContainer = styled(Flex)(({ theme }) => ({
    [mq('md')]: {
        width: theme.spots.spotSliderContentWidth,
    },
}));

const SlideContent = styled(Flex)<Pick<SlideProps, 'contentPosition'>>(
    {
        [mq(0, 'md')]: {
            transform: 'scale(0.6)',
        },
    },
    switchProp('contentPosition', {
        left: {
            width: '60%',
            alignSelf: 'flex-start',
            [mq(0, 'md')]: {
                width: '80%',
                marginLeft: '-10%',
            },
        },
        right: {
            width: '60%',
            alignSelf: 'flex-end',
            [mq(0, 'md')]: {
                width: '80%',
                marginRight: '-10%',
            },
        },
        center: {
            alignSelf: 'center',
            [mq(0, 'md')]: {
                transform: 'scale(0.6)',
                width: '140%',
            },
        },
    })
);
