import { MutableRefObject } from 'react';

type Position = {
    timeStamp: number;
    xPosition: number;
};

/**
 *
 * @param positions
 * @returns Returns a number, representing a weighted average speed in px/ms. The latter positions i weighted the highest.
 */

export const getAverageVelocity = (positions: Position[]) => {
    // Calculate the velocity between each data point
    const velocities = [];
    for (let i = 0; i < positions.length - 1; i++) {
        velocities.push(
            // Displacement / time = velocity
            (positions[i + 1].xPosition - positions[i].xPosition) /
                (positions[i + 1].timeStamp - positions[i].timeStamp)
        );
    }

    // Get the average velocity
    const x =
        velocities.reduce((prev, curr) => (Number.isFinite(curr) ? (prev + curr) / 2 : prev)) * 10;
    return x;
};

/**
 * If the desired scroll left value is less than 0, or larger than the with, the slider will be transformed on the x-axis.
 * @param desiredScrollLeft
 * @param wrapper a ref of the outer container.
 * @param slider a ref of the inner container, this is the transformed element.
 * @returns Returns true if the item was transformed.
 */

export const elasticScroll = (
    desiredScrollLeft: number,
    wrapper: MutableRefObject<HTMLDivElement>,
    slider: MutableRefObject<HTMLDivElement>,
    resistance: number
): boolean => {
    if (wrapper !== null) {
        const rightBorder = slider.current.scrollWidth;

        // Left
        if (desiredScrollLeft < 0) {
            wrapper.current.style.transform = `translateX(${
                desiredScrollLeft * resistance * -1
            }px)`;
            return true;
        }

        // Right
        if (desiredScrollLeft + slider.current.clientWidth > rightBorder) {
            wrapper.current.style.transform = `translateX(${
                (desiredScrollLeft + slider.current.clientWidth - rightBorder) * resistance * -1
            }px)`;
            return true;
        }
    }

    return false;
};
