import React, { ComponentProps, FC } from 'react';
import styled from '@emotion/styled';
import { Text } from '$components/elements/text';

export type SpotHeadingProps = {
    topSpot?: boolean;
};

type Props = SpotHeadingProps & ComponentProps<typeof SpotHeadingText>;

export const SpotHeading: FC<Props> = ({ as, topSpot, ...props }) => {
    return <SpotHeadingText {...props} as={topSpot ? 'h1' : as !== 'h1' ? as ?? 'h2' : 'h2'} />;
};

const SpotHeadingText = styled(Text)({});
