import { Theme } from '@emotion/react';

/**
 * Helper function which takes a key from BE and maps it to corresponding color.
 * If provided value is a hexColor, this will be returned as is.
 * @param theme
 * @param colorKey value from backend
 * @returns hexcolor based on key
 */
export const getColor = (theme: Theme, colorKey?: string) => {
    if (!colorKey) {
        return;
    }
    switch (colorKey) {
        case 'bg-color-base-white':
            return theme.colors.white;
        case 'bg-color-base-black':
            return theme.colors.black;

        case 'txt-color-base-white':
            return theme.colors.white;
        case 'txt-color-base-black':
            return theme.colors.black;

        case 'bg-color-primary-dark':
            return theme.colors.primaryDark;
        case 'bg-color-primary-grey':
            return theme.colors.primaryGrey;
        case 'bg-color-primary-grey-light':
            return theme.colors.primaryGreyLight;
        case 'bg-color-primary-theme':
            return theme.colors.primaryTheme;

        case 'txt-color-primary-dark':
            return theme.colors.primaryDark;
        case 'txt-color-primary-grey':
            return theme.colors.primaryGrey;
        case 'txt-color-primary-grey-light':
            return theme.colors.primaryGreyLight;
        case 'txt-color-primary-theme':
            return theme.colors.primaryTheme;

        case 'bg-color-secondary-green':
            return theme.colors.secondaryGreen;
        case 'bg-color-secondary-green-dark':
            return theme.colors.secondaryGreenDark;
        case 'bg-color-secondary-green-light':
            return theme.colors.secondaryGreenLight;
        case 'bg-color-secondary-red':
            return theme.colors.secondaryRed;
        case 'bg-color-secondary-red-dark':
            return theme.colors.secondaryRedDark;
        case 'bg-color-secondary-red-light':
            return theme.colors.secondaryRedLight;
        case 'bg-color-secondary-blue':
            return theme.colors.secondaryBlue;
        case 'bg-color-secondary-blue-dark':
            return theme.colors.secondaryBlueDark;
        case 'bg-color-secondary-blue-light':
            return theme.colors.secondaryBlueLight;
        case 'bg-color-secondary-yellow':
            return theme.colors.secondaryYellow;
        case 'bg-color-secondary-yellow-dark':
            return theme.colors.secondaryYellowDark;
        case 'bg-color-secondary-yellow-light':
            return theme.colors.secondaryYellowLight;
        case 'bg-color-secondary-purple':
            return theme.colors.secondaryPurple;
        case 'bg-color-secondary-purple-dark':
            return theme.colors.secondaryPurpleDark;
        case 'bg-color-secondary-purple-light':
            return theme.colors.secondaryPurpleLight;

        case 'txt-color-secondary-green':
            return theme.colors.secondaryGreen;
        case 'txt-color-secondary-green-dark':
            return theme.colors.secondaryGreenDark;
        case 'txt-color-secondary-green-light':
            return theme.colors.secondaryGreenLight;
        case 'txt-color-secondary-red':
            return theme.colors.secondaryRed;
        case 'txt-color-secondary-red-dark':
            return theme.colors.secondaryRedDark;
        case 'txt-color-secondary-red-light':
            return theme.colors.secondaryRedLight;
        case 'txt-color-secondary-blue':
            return theme.colors.secondaryBlue;
        case 'txt-color-secondary-blue-dark':
            return theme.colors.secondaryBlueDark;
        case 'txt-color-secondary-blue-light':
            return theme.colors.secondaryBlueLight;
        case 'txt-color-secondary-yellow':
            return theme.colors.secondaryYellow;
        case 'txt-color-secondary-yellow-dark':
            return theme.colors.secondaryYellowDark;
        case 'txt-color-secondary-yellow-light':
            return theme.colors.secondaryYellowLight;
        case 'txt-color-secondary-purple':
            return theme.colors.secondaryPurple;
        case 'txt-color-secondary-purple-dark':
            return theme.colors.secondaryPurpleDark;
        case 'txt-color-secondary-purple-light':
            return theme.colors.secondaryPurpleLight;
        // at this point we assume we hit a hexcolor and simply return with a simple check for the hash
        default:
            return `${colorKey?.startsWith('#') ? '' : '#'}${colorKey}`;
    }
};
