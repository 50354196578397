import React, { FC } from 'react';
import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';
import { IBaseSocialIcon } from '$models';
import { getColor } from '$lib/get-color';
import { getSocialIcon } from '$lib/get-icon';
import { SvgIcon } from '$components/elements/svg-icon';
import { OptionalLink } from '$components/elements/link';

export type SpotSocialLinkProps = {
    socialLink: IBaseSocialIcon;
    textColor: string;
};

type Props = SpotSocialLinkProps;

export const SpotSocialLink: FC<Props> = ({ socialLink, textColor }) => {
    const theme = useTheme();

    return (
        <>
            {socialLink && (
                <OptionalLink hideLink={true} href={socialLink.link?.url} target={socialLink.link?.target}>
                    <Container>
                        <Divider color={getColor(theme, textColor)} />
                        <SvgIcon
                            svg={getSocialIcon(socialLink.iconClass)}
                            color={getColor(theme, textColor)}
                            size={30}
                        />
                        <Divider color={getColor(theme, textColor)} />
                    </Container>
                </OptionalLink>
            )}
        </>
    );
};

const Container = styled.div(({ theme }) => ({
    display: 'grid',
    placeItems: 'center',
    columnGap: theme.sizes.siteGutter,
    gridTemplateColumns: '1fr auto 1fr',
}));

const Divider = styled.div<{ color?: string }>(({ color }) => ({
    width: '70px',
    height: '1px',
    borderTop: '1px solid',
    borderColor: color,
}));
