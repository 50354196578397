import React, { Fragment } from 'react';
import styled from '@emotion/styled';
import { IContentSpot } from '$models';
import { HeadingSpot } from './heading-spot';
import { TextSpot } from './text-spot';

import { ProductCarouselSpot } from './product-carousel-spot';
import { RaptorSpot } from './raptor-spot';
import { RaptorSpotSSR } from './raptor-spot-ssr';
import { YoutubeSpot } from './youtube-spot';
import {
    CampaignBackgroundImageSpot,
    CampaignFeaturedImageSpot,
    CampaignFeaturedLinkSpot,
    CampaignIconSpot,
    CampaignImageSpot,
} from './campaign-spots';
import { IPaperSpot } from './ipaper-spot';
import { NoSSR } from '$components/elements/no-ssr';
import { SubMenuSpot } from './sub-menu-spot';
import { ScriptSpot } from './script-spot';
import { SliderSpot } from './slider-spot/slider-spot';
import { ClubSignupSpot } from './club-signup-spot';
import { ReturnLabelSpot } from './return-label-spot';
import { SpotHeadingProps } from './components/spot-heading';
import { ImageTextSpot } from './image-text-spot';
import { ImageTextGroupSpot } from './image-text-group-spot';

type Props = {
    elements: Array<IContentSpot>;
    h1?: boolean;
};

const DynamicGridElement = (element: IContentSpot & SpotHeadingProps): JSX.Element | undefined => {
    switch (element.spotName) {
        case 'CatalogIPaper':
            return (
                <NoSSR>
                    <IPaperSpot {...(element as never)} />
                </NoSSR>
            );
        case 'Campaign Icon spot':
            return <CampaignIconSpot {...(element as never)} />;
        case 'Campaign Image spot':
            return <CampaignImageSpot {...(element as never)} />;
        case 'Campaign Background Image spot':
            return <CampaignBackgroundImageSpot {...(element as never)} />;
        case 'Campaign Featured Image spot':
            return <CampaignFeaturedImageSpot {...(element as never)} />;
        case 'Campaign Featured Link spot':
            return <CampaignFeaturedLinkSpot {...(element as never)} />;
        case 'Agillic spot':
            return <ClubSignupSpot type="agillic" {...(element as never)} />;
        case 'BC365 spot':
            return <ClubSignupSpot type="BC365" {...(element as never)} />;
        case 'BC365WithChildren spot':
            return <ClubSignupSpot type="BC365" {...(element as never)} withChildren />;
        case 'Heading spot':
            return <HeadingSpot {...(element as never)} />;
        case 'Product spot':
            return <ProductCarouselSpot {...(element as never)} />;
        case 'Product Carousel spot':
            return <RaptorSpot {...(element as never)} />;
        case 'Product Carousel Server Side Spot':
            return <RaptorSpotSSR {...(element as never)} />;
        case 'Script spot':
            return <ScriptSpot {...(element as never)} />;
        case 'Slider spot':
            return <SliderSpot {...(element as never)} />;
        case 'Product Return Label Spot':
            return <ReturnLabelSpot {...(element as never)} />;
        case 'Sub menu':
            return <SubMenuSpot {...(element as never)} />;
        case 'Text spot':
            return <TextSpot {...(element as never)} />;
        case 'Youtube spot':
            return <YoutubeSpot {...(element as never)} />;
        case 'Image and text spot':
        case 'Hero spot':
            return <ImageTextSpot {...(element as never)} />;
        case 'Image and text group spot':
            return <ImageTextGroupSpot {...(element as never)} />;
        default:
            return undefined;
    }
};

export const Spots = ({ elements, h1 }: Props): JSX.Element => (
    <SpotGrid>
        {elements
            ?.filter((x) => !!DynamicGridElement(x))
            ?.map(
                (x, i) =>
                    DynamicGridElement(x) && (
                        <Fragment key={x.spotName + '-' + i}>
                            {DynamicGridElement({ ...x, topSpot: i === 0 && h1 })}
                        </Fragment>
                    )
            )}
    </SpotGrid>
);

const SpotGrid = styled.div({
    display: 'grid',
    gridTemplateColumns: 'repeat(12, 1fr)',
    gridAutoRows: 'minmax(min-content, max-content)',
});
